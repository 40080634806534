import React, { Component } from "react";
import CheckTable from "../../../UserForm/Components/CheckTable";
import { API, graphqlOperation } from "aws-amplify";
import {
  createForm,
  createFormCathegory,
  createAnswer,
} from "../../../../graphql/mutations";
import { PopupError } from "../../../../Util/Util";
import "../../../../App.css";
import { Grid, TextField } from "@material-ui/core";
import {Roles} from '../../../../Util/Const';
import {checkAutherized} from '../../../../Routes/Urls';

const acceptedRoles=[Roles.admin.value];//Global admin

class CreateNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //So user can not use the URL and bypass router authentication
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      formID: "",
      reciever: "",
      giveAway: false,
      selectedArr: [],
    };
    this.createAForm = this.createAForm.bind(this);
    this.save = this.save.bind(this);
    this.addToFormCathegory = this.addToFormCathegory.bind(this);
  }
  //Create a new form in the database
  async createAForm() {
    try {
      let x = {
        receiver: this.state.reciever,
        step: 0,
        giveAway: this.state.giveAway,
      };
      const form = (
        await API.graphql(graphqlOperation(createForm, { input: x }))
      )?.data.createForm;
      return form;
    } catch (error) {
      console.error(error);
      throw new Error("Kunde inte skapa ett nytt formulär");
    }
  }
  //Add a connection between the cathegory and the form.
  async addToFormCathegory(cathID, formID, order) {
    try {
      await API.graphql(
        graphqlOperation(createFormCathegory, {
          input: { formCathegoriesId: formID, cathegoryID: cathID, order: order},
        })
      );
    } catch (err) {
      console.error("Error creating a new formCathegory", err);
    }
  }
  //Add answer components for every question in the cathegories.
  async addAnswerComponents(questionID, formID) {
    try {
      await API.graphql(
        graphqlOperation(createAnswer, {
          input: { formID: formID, questionID: questionID },
        })
      );
    } catch (error) {
      console.error(error);
      throw new Error("Kunde inte koppla fråga med nytt svar");
    }
  }
  //Only add answercomponent to the non-archived questions
  //This means forms will only included active questions.
  async save(userSelectedArr) {
    if (this.state.reciever === "") {
      PopupError("Måste ange mottagare");
    } else if(userSelectedArr.length < 1){
      PopupError("Måste finnas minst en kategori");
    }else {
      try {
        const form = await this.createAForm();
        for(let i =0; i < userSelectedArr.length; i++){
          this.addToFormCathegory(userSelectedArr[i].id, form.id, i)
        }
        for (let i = 0; i < userSelectedArr.length; i++) {
          for (let j = 0; j < userSelectedArr[i].questions.items.length; j++) {
            if (!userSelectedArr[i].questions.items[j].isArchived) {
              this.addAnswerComponents(
                userSelectedArr[i].questions.items[j].id,
                form.id
              );
            }
          }
        }
        this.props.history.goBack();
      } catch (error) {
        window.alert(error);
      }
    }
  }
  render() {
    if(!this.state.autherized){
      return null;
    }
    return (
      <>
        <h1>Skapa formulär</h1>
        <Grid container>
          <Grid item container xs={12} md={4}>
            <Grid item xs={12}>
              <h3>Namn:</h3>
            </Grid>
            <Grid item xs={12}>
              <div className="utvecklaTextInput">
                <TextField
                  fullWidth
                  value={this.state.reciever}
                  onChange={(event) =>
                    this.setState({ reciever: event.target.value })
                  }
                  label="Mottagare"
                  variant="filled"
                />
              </div>
            </Grid>
            <Grid item sx={12} style={{ display:"flex", flexDirection:"row", alignItems:"center"}}>
              <h3> Är formuläret ett give-away? </h3>
              <input
                style={{ marginLeft: "20px" }}
                type="checkbox"
                value={this.state.giveAway}
                onChange={() =>
                  this.setState({ giveAway: !this.state.giveAway })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <CheckTable save={this.save}/>
      </>
    );
  }
}
export default CreateNewForm;
