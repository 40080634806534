import API, { graphqlOperation } from "@aws-amplify/api";
import { Grid } from "@material-ui/core";
import React from "react";
import { Component } from "react";
import { listAgreements } from "../../../graphql/queries";
import { PopupError } from "../../../Util/Util";
import NewlineText from "./NewlineText";

class UserAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreement:"",
    };
  }
  
  async fetchAgreement() {
    try {
      let agreement = (await API.graphql({query:listAgreements, authMode: "AWS_IAM",}))?.data?.listAgreements?.items;
      //There should only be 1 row of Agreeement in the Database!
      if (agreement.length > 1) {
        PopupError("Problem retrieveing user agreement");
      } else {
        this.setState({
          agreement: agreement[0],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  componentDidMount(){
    this.fetchAgreement();
  }

  render() {
    return (
      <Grid item xs={12}>
        <h2>Användaravtal</h2>
        {this.props.giveAway && <NewlineText text={this.state.agreement?.giveAwayText || ""} />}
        {!this.props.giveAway && <NewlineText text={this.state.agreement?.text || ""}/>}
        </Grid>
    );
  }
}
export default UserAgreement;
