/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:68dfe3c3-dfd7-4fbf-a492-b1ad684a91c9",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_AqzjaOd8f",
    "aws_user_pools_web_client_id": "4g1rn25ane4d4gham4n8n2c06c",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://72wftyllwvc2vnh7ijbqu3k6uq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://7qu9r5a4kh.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "livshjulet4440a2f8d2704716bc50e618f257083b161550-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
