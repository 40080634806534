import { Card, Grid } from "@material-ui/core";
import { PostAdd } from "@material-ui/icons";
import { API, graphqlOperation } from "aws-amplify";
import React, { Component } from "react";
import RoundButton from "../../../Components/RoundButton";
import { listCathegorys } from "../../../graphql/queries";
import { reorder } from "../../../Util/Util";
import FormCathegories from "./FormCategories";

const initialState = {
  cathegoryDropID: "",
  isArchived: false,
  question: "",
  selectedArr: [],
};

class CheckTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: initialState,
      questions: [],
      cathegories: [],
      selectedArr: [],
    };
    this.listSelected = this.listSelected.bind(this);
    this.listRemove = this.listRemove.bind(this);
    this.moveCathegory = this.moveCathegory.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.fetchCathegories();
  }
  //Adds the cathegory to the array when user selects the cathegory
  listSelected(cathegory) {
    const tempSelectedArr = [...this.state.selectedArr, cathegory];
    this.setState({ selectedArr: tempSelectedArr });
  }
  //Removes the cathegory from the array when user deselects the cathegory
  listRemove(cathegory) {
    const tempSelectedArr = this.state.selectedArr.filter((item) => item.id !== cathegory.id);
    this.setState({ selectedArr: tempSelectedArr });
  }
  //To give the form a list with the selected cathegories.
  save() {
    this.props.save(this.state.selectedArr);
  }

  moveCathegory(cathegory, direction) {
    let tempSelectedArr = this.state.selectedArr;
    tempSelectedArr = reorder(tempSelectedArr, cathegory.order, cathegory.order + direction);
    this.setState({ selectedArr: tempSelectedArr });
  }

  //To get a list of all categories registered in the DB.
  async fetchCathegories() {
    try {
      const CathegoryData = (await API.graphql(graphqlOperation(listCathegorys))).data.listCathegorys.items;
      this.setState({ cathegories: CathegoryData.map((x) => ({ ...x, order: 0 })) });
    } catch (err) {
      console.error("Error when listing all cathegories", err);
    }
  }

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card style={{ padding: "20px" }}>
              <h2>Valda</h2>
              {this.state.selectedArr
                .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                .map((x) => (
                  <FormCathegories
                    move={this.moveCathegory}
                    selected={true}
                    key={x.id}
                    cathegory={x}
                    listSelected={this.listSelected}
                    listRemove={this.listRemove}
                  />
                ))}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card style={{ padding: "20px" }}>
              <h2>Möjliga</h2>
              {this.state.cathegories
                .filter((cathegory) => !this.state.selectedArr.includes(cathegory))
                .map((x) => (
                  <FormCathegories
                    move={this.moveCathegory}
                    selected={false}
                    key={x.id}
                    cathegory={x}
                    listSelected={this.listSelected}
                    listRemove={this.listRemove}
                  />
                ))}
            </Card>
          </Grid>
        </Grid>
        <div style={{ display: "flex", flexDirection: "row", marginTop: "40px" }}>
          <RoundButton onClick={this.save}>
            <PostAdd />
            <p> Skapa nytt form</p>
          </RoundButton>
        </div>
      </div>
    );
  }
}
export default CheckTable;
