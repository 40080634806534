import React from "react";
import { Route, Switch } from "react-router-dom";
import Error from "../Pages/Error/ErrorPage";
import { UnauthenticatedUrls, AuthenticatedUrls, url } from "./Urls";
import Landing from "../Pages/Landing/Landing";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import UserView from "../Pages/UserForm/UserView";
import Admin from "../Pages/Admin/Admin";
import AppliedRoute from "./AppliedRoute";
import CheckInOut from "../Pages/CheckInOut/CheckInOut";

class Router extends React.Component {
  render() {
    return (
      <Switch>
        <UnAuthenticatedRoute component={Landing} exact path={UnauthenticatedUrls.landing.path} props={this.props.childProps} />
        {/* Undersidor */}
        <UnAuthenticatedRoute component={Landing} exact path={UnauthenticatedUrls.login.path} props={this.props.childProps} />
        <UnAuthenticatedRoute component={Landing} exact path={UnauthenticatedUrls.recover.path} props={this.props.childProps} />

        <AppliedRoute component={UserView} path={UnauthenticatedUrls.form.path+":id"} props={this.props.childProps} />
        {/* Inte undersidor*/}
        <AuthenticatedRoute component={Admin} exact path={url.root} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={url.home} props={this.props.childProps} />
        {/* Undersidor */}
        <AuthenticatedRoute component={CheckInOut} path={url.utvecklandeLedande} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={url.adminForm} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={url.adminView} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={url.createForm} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={url.cathegories} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={url.userWeeklyOverview} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={AuthenticatedUrls.adminCustomers.path} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={AuthenticatedUrls.adminCustomerEdit.path} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={AuthenticatedUrls.adminCustomersUsers.path} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={AuthenticatedUrls.adminCustomersUsersEdit.path} props={this.props.childProps} />
        <AuthenticatedRoute component={Admin} exact path={AuthenticatedUrls.userAgreementEdit.path} props={this.props.childProps} />

        {/* Admin */}
        <AuthenticatedRoute component={Error} exact path={AuthenticatedUrls.error.path} props={this.props.childProps} />
        <UnAuthenticatedRoute component={Error} exact path={AuthenticatedUrls.error.path} props={this.props.childProps} />

        <Route component={Error} exact props={this.props.childProps} />
      </Switch>
    );
  }
}

export default Router;
