import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UnauthenticatedUrls } from "./Urls";

const AuthenticatedRoute = ({ component: C, props: cProps, ...rest }) => {
  return <Route {...rest} render={(props) => (cProps.app.isAuthenticated ? <C {...props} {...cProps} /> : <Redirect to={UnauthenticatedUrls.login.path} />)} />;
};
export default AuthenticatedRoute;


