import React, { Component } from 'react';


const initialState = { questionObj: {}}

class FormQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formState: initialState,
            questionObj: this.props.question
        }
    }


    render() {
        return (
            <div>
                <p className="leftAlignRow" >{this.state.questionObj.question}</p>
            </div>);
    }
}
export default FormQuestion;