import React from "react";
import logo from "../Assets/Images/mona-wassermann-logo-vit.png";
import { Grid } from "@material-ui/core";

class HeaderBar extends React.Component {
  render() {
    return (
      <div className="navigationMenu">
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <img style={{ display: "inline", width: "100%", height: "100%", maxWidth: "400px", maxHeight: "114px" }} src={logo} alt="Logo" />
          </Grid>  
        </Grid>
      </div>
    );
  }
}

export default HeaderBar;
