import { Auth } from "aws-amplify";
import React from "react";
import Loading from "../../../Components/Loading";
import { UnauthenticatedUrls } from "../../../Routes/Urls";
import { PopupError } from "../../../Util/Util";
import RoundButton from "../../../Components/RoundButton";
import { Grid, TextField } from "@material-ui/core";

class Recover extends React.Component {
  constructor(props) {
    super(props);
    let infoProvided = this.props.location.state?.email != null;
    this.state = {
      infoProvided,
      isLoading: true,
      email: this.props.location.state?.email || "",
      oldPassword: this.props.location.state?.password || "",
      newPassword: "",
      verificationCode: "",
      error: "",
    };

    this.validateLogin = this.validateLogin.bind(this);
    this.Login = this.Login.bind(this);
  }
  async componentDidMount() {
    this.setState({ isLoading: false });
    if(this.state.email===""){
      this.props.history.push(UnauthenticatedUrls.login.path)
    }
  }

  validateLogin() {
    return this.state.newPassword.length > 7 && this.state.email !== "";
  }

  async Login(event) {
    event.preventDefault();
    try {
      this.setState({ isLoading: true });
      let user = this.state.user;
      if (this.props.location.state?.code) {
        await Auth.forgotPasswordSubmit(this.state.email, this.state.verificationCode, this.state.newPassword);
        user = await Auth.signIn(this.state.email, this.state.newPassword);
      } else {
        if (user == null) {
          user = await Auth.signIn(this.state.email, this.state.oldPassword);
        }
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          user = await Auth.completeNewPassword(user, this.state.newPassword);
        }
      }

      await this.props.userSignIn();
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        PopupError("Användaren finns inte.");
      } else {
        console.error("Login:", err);
      }
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }
    return (
      <Grid container>
        <Grid item xs={10} sm={4}>
          <h1>Skapa nytt lösenord</h1>
          <div>          
            {this.props.location.state?.code && (
              <div className="utvecklaTextInput">
                <TextField
                  variant="standard"
                  label="Verifieringskod"
                  placeholder="Verifieringskod"
                  autoComplete= "new-password"
                  fullWidth
                  value={this.state.verificationCode}
                  error={this.state.verificationCode.length !== 6}
                  onChange={(event) => this.setState({ verificationCode: event.target.value })}
                />
              </div>
            )}
            <p><b>Minst 8 tecken i det nya lösenordet</b></p>
            <div className="utvecklaTextInput">
              <TextField
                variant="standard"
                label="Nytt lösenord"
                type="password"
                placeholder="Ange lösenord"
                autoComplete= "new-password"
                fullWidth
                value={this.state.newPassword}
                onChange={(event) => this.setState({ newPassword: event.target.value })}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <RoundButton disabled={!this.validateLogin()} onClick={this.Login}>
                <p>Spara och Logga in</p>
              </RoundButton>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default Recover;
