import React from "react";
import { Link } from "react-router-dom";
import { MWGreen } from "../Util/Const";
import RoundButton from "./RoundButton";

//A button which takes us somewhere using "Link"
class RoundButtonLink extends React.Component {
  render() {
    return (
      <Link to={this.props.to} style={{ textDecoration: "none" }}>
        <RoundButton style={this.props.style} onClick={null}>
          {this.props.children}
        </RoundButton>
      </Link>
    );
  }
}

export default RoundButtonLink;
