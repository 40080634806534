import { Card, Grid } from "@material-ui/core";
import React, { Component } from "react";
import RoundButton from "../../../../Components/RoundButton";
import StarRating from "../../../../Components/StarRating";
import { PopupConfirm } from "../../../../Util/Util";

class WeeklyReflectionShow extends Component {
  render() {
    return (
      <>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Card style={{ marginBottom: "40px", paddingLeft: "20px", paddingTop: "40px", paddingBottom: "40px" }}>
              <p>
                <b>Mål för veckan</b> <br /> {this.props.weekly.goal}
              </p>
              <p>
                <b>Viktigt att tänka på</b> <br /> {this.props.weekly.important}
              </p>
              <p>
                <b>Vad behöver jag hjälp med?</b><br /> {this.props.weekly.help}
              </p>

              {this.props.weekly.reflection !== "" && (
                <>
         
          
                  <div>
                    <p><b>Hur nådde jag mitt mål?</b></p>
                    <StarRating disabled={true} stars={this.props.weekly.strategyWorkOut} />
                  </div>
                  <p>
                    <b>Vad gjorde jag bra?</b> <br /> {this.props.weekly.good}
                  </p>
                  <p>
                    <b>Vad gjorde jag mindre bra?</b> <br /> {this.props.weekly.bad}
                  </p>
                  <p>
                    <b>Hur löser jag det?</b> <br /> {this.props.weekly.solution}
                  </p>
                  <p>
                    <b>Vad har jag lärt mig?</b> <br /> {this.props.weekly.reflection}
                  </p>
                </>
              )}
            </Card>
          </Grid>
        </Grid>

        {!this.props.readOnly && this.props.weekly.reflection === "" && (
          <RoundButton onClick={() => PopupConfirm("Gå vidare till reflektion", () => this.props.ShowReflection())}>
            <p>Gå till reflektion</p>
          </RoundButton>
        )}
        {!this.props.readOnly && this.props.weekly.reflection !== "" && (
          <RoundButton onClick={() => PopupConfirm("Starta ny vecka?", () => this.props.Reset())}>
            <p>Starta ny vecka</p>
          </RoundButton>
        )}
      </>
    );
  }
}
export default WeeklyReflectionShow;
