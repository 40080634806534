import React from "react";
import { Component } from "react";
import { PopupConfirm } from "../../../../Util/Util";
import { Card, TextField, Grid } from "@material-ui/core";
import uuid from "react-uuid";
import DailyGoal from "../../../../Components/DailyGoal";
import RoundButton from "../../../../Components/RoundButton";
import IconButton from "../../../../Components/IconButton";
import { AddCircle, RemoveCircle } from "@material-ui/icons";

class DailyPlaning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyGoals: [],
      dailyGoalText: "",

      //Daily questions from the DB
      dailyQuestions: [],

      //The users' answers to the daily questions
      dailyQuestionAnswers: [],
    };

    this.addDailyGoalToList = this.addDailyGoalToList.bind(this);
    this.save = this.save.bind(this);
    this.addDailyQuestionToList = this.addDailyQuestionToList.bind(this);
    this.removeDailyGoal = this.removeDailyGoal.bind(this);
  }

  //User creates a new goal
  addDailyGoalToList() {
    if (this.state.dailyGoalText !== "") {
      var goal = {
        tempID: uuid(),
        toDo: this.state.dailyGoalText,
        //TODO!, USE THE PRIORITY TO CHANGE THE ORDER AMONG THE DAILY GOALS.
        priority: 0,
      };
      this.setState({
        dailyGoals: [...this.state.dailyGoals, goal],
        dailyGoalText: "",
      });
    }
  }

  removeDailyGoal(tempID) {
    let dailyGoals = this.state.dailyGoals;
    dailyGoals = dailyGoals.filter((goal) => goal.tempID !== tempID);
    this.setState({ dailyGoals });
  }

  //Answer the question
  addDailyQuestionToList(answer, dailyQuestionID) {
    let newDailyQuestionAnswers = this.state.dailyQuestionAnswers;
    let newDailyQuestionAnswer = newDailyQuestionAnswers.find(
      (answer) => answer.dailyQuestionID === dailyQuestionID
    );

    if (!newDailyQuestionAnswer) {
      newDailyQuestionAnswers.push({
        answer: answer,
        dailyQuestionID: dailyQuestionID,
      });
    } else {
      newDailyQuestionAnswer.answer = answer;
    }
    this.setState({
      dailyQuestionAnswers: newDailyQuestionAnswers,
    });
  }

  // Check so that the user can't continue from planing to reflection before filled in Daily goals and answered questions.
  validate() {
    if (this.state.dailyGoals.length + this.props.dailyGoals.length < 1) {
      return false;
    }
    if (this.state.dailyQuestionAnswers.length < 1) {
      return false;
    }
    for (let i = 0; i < this.state.dailyQuestionAnswers.length; i++) {
      if (this.state.dailyQuestionAnswers[i].answer === "") {
        return false;
      }
    }
    return true;
  }

  // Planing -> Takes all the created daily-goals and daily-question answers to the questions and send them to the DB
  async save() {
    this.props.SavePlan(this.state.dailyGoals, this.state.dailyQuestionAnswers);
  }

  render() {
    const firstColumnWidhtXS = 10;
    const firstColumnWidhtMD = 4;
    const secondColumnWidhtXS = 2;
    const secondColumnWidhtMD = 1;
    return (
      <>
        <h1>Utvecklande Ledarskap</h1>
        <h2>Daglig planering</h2>
        <p>
          <b>Ange dina dagliga mål (max 5) minst 1 mål</b>
        </p>
        <Grid container>
          {this.props.dailyGoals.map((goal) => (
            <Grid
              container
              item
              className="gridInListStyle"
              key={goal.id}
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={firstColumnWidhtXS} md={firstColumnWidhtMD}>
                <Card>
                  <DailyGoal text={goal.toDo} />
                </Card>
              </Grid>
              <Grid item xs={secondColumnWidhtXS} md={secondColumnWidhtMD}>
                <IconButton
                  onClick={() =>
                    PopupConfirm("Ta bort?", () =>
                      this.props.DeleteGoal(goal.id)
                    )
                  }
                >
                  <RemoveCircle />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          {this.state.dailyGoals.map((goal) => (
            <Grid
              container
              item
              className="gridInListStyle"
              key={goal.tempID}
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={firstColumnWidhtXS} md={firstColumnWidhtMD}>
                <Card>
                  <DailyGoal text={goal.toDo} />
                </Card>
              </Grid>
              <Grid item xs={secondColumnWidhtXS} md={secondColumnWidhtMD}>
                <IconButton
                  onClick={() =>
                    PopupConfirm("Ta bort?", () =>
                      this.removeDailyGoal(goal.tempID)
                    )
                  }
                >
                  <RemoveCircle />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Grid
            container
            item
            className="gridInListStyle"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={firstColumnWidhtXS} md={firstColumnWidhtMD}>
              <TextField
                className="textField"
                onChange={(event) =>
                  this.setState({ dailyGoalText: event.target.value })
                }
                value={this.state.dailyGoalText}
                label="Mitt dagliga mål"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={secondColumnWidhtXS} md={secondColumnWidhtMD}>
              <IconButton onClick={this.addDailyGoalToList}>
                <AddCircle />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item>
            <h3>För att lyckas behöver jag*</h3>
          </Grid>
          {this.props.dailyQuestions.map(
            (dailyQuestion) =>
              !dailyQuestion.isReflection && (
                <Grid
                  container
                  item
                  className="gridInListStyle"
                  alignItems="center"
                  spacing={2}
                  key={dailyQuestion.id}
                >
                  <Grid item xs={firstColumnWidhtXS} md={firstColumnWidhtMD}>
                    <TextField
                      className="textField"
                      fullWidth
                      onChange={(event) =>
                        this.addDailyQuestionToList(
                          event.target.value,
                          dailyQuestion.id
                        )
                      }
                      label={dailyQuestion.Question + "*"}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
              )
          )}

          <Grid item>
            <RoundButton
              disabled={!this.validate()}
              onClick={() =>
                PopupConfirm("Är du nöjd med dina mål?", this.save)
              }
            >
              <p>Spara</p>
            </RoundButton>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default DailyPlaning;
