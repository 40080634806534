import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createCathegory, createQuestion } from "../../../graphql/mutations";
import { getCathegory, listCathegorys } from "../../../graphql/queries";
import Select from "react-select";
import Cathegory from "./Components/Cathegory";
import AddIcon from "@material-ui/icons/Add";
import RoundButton from "../../../Components/RoundButton";
import { Card, Grid, Paper, TextField } from "@material-ui/core";
import Empty from "../../../Components/Empty";
import { checkAutherized } from "../../../Routes/Urls";
import { Roles } from "../../../Util/Const";

const acceptedRoles = [Roles.admin.value]; //Global admin

const initialState = {
  cathegory: "",
  cathegoryDropID: "",
  isArchived: false,
  question: "",
  order: 0,
};

class Cathergories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      formState: { ...initialState },
      questions: [],
      cathegories: [],
      loading: true,
    };
    this.addNewCathegory = this.addNewCathegory.bind(this);
    this.updateFormState = this.updateFormState.bind(this);
    this.createQuestionInForm = this.createQuestionInForm.bind(this);
    this.fetchCathegories = this.fetchCathegories.bind(this);
  }
  async componentDidMount() {
    if (this.state.autherized) {
      await this.fetchCathegories();
      this.setState({ loading: false });
    }
  }

  updateFormState(key, value) {
    let temp = this.state.formState;
    temp[key] = value;
    this.setState({ formState: temp });
  }
  //Add a new cathegory
  async addNewCathegory() {
    try {
      if (!this.state.formState.cathegory) return;
      const cathegory = {
        cathegory: this.state.formState.cathegory,
        isArchived: this.state.formState.isArchived,
      };
      this.setState({ formState: { ...initialState } });
      await API.graphql(
        graphqlOperation(createCathegory, { input: cathegory })
      );
      this.fetchCathegories();
    } catch (err) {
      console.error("error creating cathegory:", err);
    }
  }
  //Create a new question
  async createQuestionInForm() {
    try {
      if (
        !this.state.formState.cathegoryDropID ||
        !this.state.formState.question
      )
        return;
      const retrievedCathegory = await API.graphql(
        graphqlOperation(getCathegory, {
          id: this.state.formState.cathegoryDropID,
        })
      );
      const question = {
        question: this.state.formState.question,
        isArchived: this.state.formState.isArchived,
        cathegoryID: retrievedCathegory.data.getCathegory.id,
        order: this.state.formState.order,
      };
      this.addQuestion(question);
    } catch (err) {
      console.error("error locating existing Cathegory:", err);
    }
  }

  //Input param is a question object the created cathegory ID.
  async addQuestion(question) {
    try {
      await API.graphql(graphqlOperation(createQuestion, { input: question }));
      this.setState({ formState: { ...initialState } });
      this.fetchCathegories();
    } catch (err) {
      console.error("error creating question:", err);
    }
  }

  //To get a list of all categories registered in the DB.
  //Listing the catheogries
  async fetchCathegories() {
    try {
      const CathegoryData = (
        await API.graphql(graphqlOperation(listCathegorys))
      ).data.listCathegorys.items;
      this.setState({ cathegories: CathegoryData });
    } catch (err) {
      console.error("Error when listing all cathegories", err);
    }
  }
  render() {
    if (!this.state.autherized) {
      return null;
    }
    if (this.state.loading) {
      return <p>Loading</p>;
    }
    return (
      <>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6} className="gridInListStyle">
            <Card style={{ width: "100%", padding: "20px" }}>
              <Grid item xs={12}>
                <h2>Skapa kategorier</h2>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Här kan du skapa kategorier. Kom ihåg att du måste ladda om
                  sidan innan den dyker upp i de olika listorna på sidan
                </p>
              </Grid>
              <Grid item xs={12}>
                <h3>Skriv in den nya kategorin</h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="textField"
                  fullWidth
                  onChange={(event) =>
                    this.updateFormState("cathegory", event.target.value)
                  }
                  value={this.state.formState.cathegory}
                  label="Skriv in kategori"
                  variant="filled"
                />
              </Grid>
              <Grid style={{ marginTop: "40px" }} item xs={12}>
                <RoundButton onClick={this.addNewCathegory}>
                  <AddIcon />
                  <p>Skapa kategori</p>
                </RoundButton>
              </Grid>
            </Card>
          </Grid>
          <Grid container item xs={12} md={6} className="gridInListStyle">
            <Paper style={{ width: "100%", padding: "20px" }}>
              <Grid item xs={12}>
                <h2>Skapa frågor</h2>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Här kan du skapa frågor till livshjulsformulären. När du
                  skapar en fråga måste du tilldela den en kategori
                </p>
              </Grid>
              <Grid item xs={12}>
                <h3>Skriv in frågetexten</h3>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="textField"
                  fullWidth
                  onChange={(event) =>
                    this.updateFormState("question", event.target.value)
                  }
                  value={this.state.formState.question}
                  label="Skriv in fråga"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="textField"
                  fullWidth
                  onChange={(event) =>
                    this.updateFormState("order", event.target.value)
                  }
                  value={this.state.formState.order}
                  label="Ordningsnummer"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
                <h3>Välj en kategori</h3>
              </Grid>
              <Grid item xs={12}>
                <Select
                  id="dropListCat"
                  value={this.state.cathegory}
                  onChange={(event) =>
                    this.updateFormState("cathegoryDropID", event.value)
                  }
                  options={this.state.cathegories.map((x) => ({
                    value: x.id,
                    label: x.cathegory,
                  }))}
                ></Select>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "40px" }}>
                <RoundButton onClick={this.createQuestionInForm}>
                  <AddIcon />
                  <p>Skapa fråga</p>
                </RoundButton>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid>
          <table id="contentTable">
            <thead>
              <tr>
                <th>
                  <h2>Katalog över kategorier och frågor</h2>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.cathegories.length === 0 && (
                <tr>
                  <td>
                    <Empty />
                  </td>
                </tr>
              )}
              {this.state.cathegories.map((x) => (
                <tr key={x.id}>
                  <td>
                    <Cathegory cathegory={x} reload={this.fetchCathegories} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      </>
    );
  }
}

export default Cathergories;
