import { Auth, API } from "aws-amplify";

async function RunPostQuerry(path, myInit) {
  myInit = {
    ...myInit,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  return await API.post("AdminQueries", path, myInit);
}
async function RunGetQuerry(path, myInit) {
  myInit = {
    ...myInit,
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };
  return await API.get("AdminQueries", path, myInit);
}

export async function setUserPassword(username, password) {
  let myInit = {
    body: {
      username,
      password,
    },
  };
  return await RunPostQuerry("/setUserPassword", myInit);
}

export async function AddUserToGroup(username, groupname) {
  let myInit = {
    body: {
      username,
      groupname,
    },
  };
  return await RunPostQuerry("/addUserToGroup", myInit);
}

export async function GetUser(username) {
  try {
    let myInit = {
      queryStringParameters: {
        username: username,
      },
    };
    return await RunGetQuerry("/getUser", myInit);
  } catch (err) {
    console.error("getUser", err);
  }
}
export async function ListUsers() {
  try {
    let myInit = {};
    return await RunGetQuerry("/listUsers", myInit);
  } catch (err) {
    console.error("listUsers", err);
  }
}

export async function DisableUser(username) {
  try {
    let myInit = {
      body: {
        username,
      },
    };
    return await RunPostQuerry("/disableUser", myInit);
  } catch (err) {
    console.error("disableUser", err);
  }
}
export async function AdminCreateUser(user) {
  try {
    let myInit = {
      body: {
        username: user.email,
        name: user.name,
        email: user.email,
      },
    };
    return await RunPostQuerry("/adminCreateUser", myInit);
  } catch (err) {
    console.error("adminCreateUser", err);
  }
}
export async function EnableUser(username) {
  try {
    let myInit = {
      body: {
        username,
      },
    };
    return await RunPostQuerry("/enableUser", myInit);
  } catch (err) {
    console.error("enableUser", err);
  }
}
export async function ConfirmUserSignUp(username) {
  try {
    let myInit = {
      body: {
        username,
      },
    };
    return await RunPostQuerry("/confirmUserSignUp", myInit);
  } catch (err) {
    console.error("confirmUserSignUp", err);
  }
}
export async function DeleteUser(username) {
  try {
    let myInit = {
      body: {
        username,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return await RunPostQuerry("/deleteUser", myInit);
  } catch (err) {
    console.error("deleteUser", err);
  }
}

export async function ListGroupsForUser(username) {
  try {
    let myInit = {
      queryStringParameters: {
        username: username,
      },
    };
    return await RunGetQuerry("/listGroupsForUser", myInit);
  } catch (err) {
    console.error("listGroupsForUser", err);
  }
}

export async function RemoveUserFromGroup(username, groupname) {
  try {
    let myInit = {
      body: {
        username,
        groupname,
      },
    };
    return await RunPostQuerry("/removeUserFromGroup", myInit);
  } catch (err) {
    console.error("removeUserFromGroup", err);
  }
}
