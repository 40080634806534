import React from "react";
import { Switch } from "react-router-dom";
import CustomRoute from "../../Routes/CustomRoute";
import FormsList from "./Sections/Form/FormsList";
import Cathergories from "./Sections/Cathergories";
import CreateNewForm from "./Sections/Form/CreateNewForm";
import FormView from "./Sections/FormView";
import AppliedRoute from "../../Routes/AppliedRoute";
import { AuthenticatedUrls, url } from "../../Routes/Urls";
import UserWeeklyOverview from "./Sections/UserWeeklyOverview/UserWeeklyOverview";
import SectionEditCustomer from "./Sections/Customer/SectionEditCustomer";
import SectionCustomers from "./Sections/Customer/SectionCustomers";
import SectionUsersEdit from "./Sections/User/SectionUsersEdit";
import SectionUsers from "./Sections/User/SectionUsers";
import AgreementEdit from "./Sections/Form/AgreementEdit";

class Admin extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <AppliedRoute path={url.adminForm} exact component={FormsList} props={this.props.app} />
          <AppliedRoute path={url.adminView} component={FormView} props={this.props.app} />
          <AppliedRoute path={url.userAgreementEdit} exact component={AgreementEdit} props={this.props.app} />
          <AppliedRoute path={url.createForm} exact component={CreateNewForm} props={this.props.app} />
          <CustomRoute path={url.cathegories} exact component={Cathergories} props={this.props.app} />
          <CustomRoute path={url.userWeeklyOverview} exact component={UserWeeklyOverview} props={this.props.app} />
          <CustomRoute path={AuthenticatedUrls.adminCustomerEdit.path} exact component={SectionEditCustomer} props={this.props.app} />
          <CustomRoute path={AuthenticatedUrls.adminCustomers.path} exact component={SectionCustomers} props={this.props.app} />
          <CustomRoute path={AuthenticatedUrls.adminCustomersUsersEdit.path} exact component={SectionUsersEdit} props={this.props.app} />
          <CustomRoute path={AuthenticatedUrls.adminCustomersUsers.path} exact component={SectionUsers} props={this.props.app} />
        </Switch>
      </div>
    );
  }
}

export default Admin;
