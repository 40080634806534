import React, { Component } from "react";

import DailyPlaning from "./DailyPlaning";
import DailyReflection from "./DailyReflection";
import { API, graphqlOperation } from "aws-amplify";
import { listDailyGoals, listDailyQuestions, listDailyAnswers } from "../../../../graphql/queries";
import { PopupError } from "../../../../Util/Util";
import { createDailyGoal, createDailyAnswer, updateDailyGoal, deleteDailyGoal, deleteDailyAnswer } from "../../../../graphql/mutations";
import DailyReflectionView from "./DailyReflectionView";

const steps = { planing: "1", reflection: "2", show: "3" };
class Daily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyGoals: [],
      dailyQuestions: [],
      dailyAnswers: [],
      step: steps.planing,
      loading: true,
    };
    this.SavePlan = this.SavePlan.bind(this);
    this.SaveReflection = this.SaveReflection.bind(this);
    this.fetchDailyQuestion = this.fetchDailyQuestion.bind(this);
    this.fetchDailyGoals = this.fetchDailyGoals.bind(this);
    this.fetchDailyQuestionAnswers = this.fetchDailyQuestionAnswers.bind(this);
    this.Reset = this.Reset.bind(this);
    this.DeleteGoal = this.DeleteGoal.bind(this);
  }

  async componentDidMount() {
    let dailyQuestions = await this.fetchDailyQuestion();

    await this.fetchDailyGoals();
    await this.fetchDailyQuestionAnswers(dailyQuestions);

    this.setState({ loading: false, dailyQuestions });
  }
  //Retrieve already existing questions from the database
  async fetchDailyQuestion() {
    try {
      return (await API.graphql(graphqlOperation(listDailyQuestions)))?.data?.listDailyQuestions?.items;
    } catch (error) {
      console.error("Could not retrieve the daily questions from database", error);
      PopupError("Kunde inte ladda in Dagliga frågor");
    }
  }

  //Retrieve already existing goals from the database
  async fetchDailyGoals() {
    try {
      let dailyGoals = (await API.graphql(graphqlOperation(listDailyGoals)))?.data?.listDailyGoals?.items;
      let userSpecificGoals = dailyGoals.filter((goal) => goal.userID === this.props.userID);
      this.setState({ dailyGoals: userSpecificGoals });
    } catch (error) {
      console.error("Could not retrieve the daily questions from database", error);
      PopupError("Kunde inte ladda in Dagliga frågor");
    }
  }

  async fetchDailyQuestionAnswers(dailyQuestions) {
    let dailyAnswersDB = (await API.graphql(graphqlOperation(listDailyAnswers)))?.data?.listDailyAnswers?.items;
    let userAnswers = dailyAnswersDB.filter((answer) => answer.userID === this.props.userID);
    try {
      let answerList = [];

      for (let i = 0; i < userAnswers.length; i++) {
        for (let j = 0; j < dailyQuestions.length; j++) {
          if (userAnswers[i].questionDailyID === dailyQuestions[j].id) {
            answerList.push({ answer: userAnswers[i], question: dailyQuestions[j] });
          }
        }
      }
      let step = steps.planing;
      //Could not find any answers to reflection questions.
      if (answerList.length > 0 && answerList.find((answer) => answer.question.isReflection === true) == null) {
        step = steps.reflection;
      }
      //Find one of each
      else if (
        answerList.length > 0 &&
        answerList.find((answer) => answer.question.isReflection === true) != null &&
        answerList.find((answer) => answer.question.isReflection === false) != null
      ) {
        step = steps.show;
      }

      this.setState({ step });

      this.setState({ dailyAnswers: answerList });
    } catch (error) {
      console.error("Could not retrieve the daily questions from database", error);
      PopupError("Kunde inte ladda in Dagliga frågor");
    }
  }

  //Save plannmign
  async SavePlan(goalList, answerList) {
    try {
      this.setState({ loading: true });
      //Save new goals if we have any
      if (goalList.length > 0) {
        for (let i = 0; i < goalList.length; i++) {
          let savingDailyGoalToDB = {
            priority: goalList[i].priority,
            toDo: goalList[i].toDo,
            userID: this.props.userID,
            done: false,
          };
          await API.graphql(graphqlOperation(createDailyGoal, { input: savingDailyGoalToDB }));
        }
      }
      //Save answers to daily questions
      for (let i = 0; i < answerList.length; i++) {
        let savingDailyQuestionToDB = {
          questionDailyID: answerList[i].dailyQuestionID,
          userID: this.props.userID,
          answer: answerList[i].answer,
        };
        await API.graphql(graphqlOperation(createDailyAnswer, { input: savingDailyQuestionToDB }));
      }
      await this.fetchDailyGoals();
      await this.fetchDailyQuestionAnswers(this.state.dailyQuestions);
    } catch (error) {
      console.error("Daily.js save:", error);
      PopupError("Kunde inte spara");
    }
    this.setState({ loading: false });
  }

  //SaveReflection
  //Removed the goals marked as done and updates the reflection -texts if the user has made any
  async SaveReflection(dailyGoals, reflectionAnswers) {
    try {
      this.setState({ loading: true });
      //Update daily goals with reflection
      for (let dailyGoal of dailyGoals) {
        let savingDailyGoalReflectionToDB = {
          id: dailyGoal.id,
          goodReflection: dailyGoal.goodReflection,
          badReflection: dailyGoal.badReflection,
          lessonLearned: dailyGoal.lessonLearned,
          done: dailyGoal.done,
        };
        await API.graphql(graphqlOperation(updateDailyGoal, { input: savingDailyGoalReflectionToDB }));
      }
      //Save answer to reflective daily questions
      for (let dailyAnswer of reflectionAnswers) {
        let dailyAnswerToSave = {
          questionDailyID: dailyAnswer.dailyQuestionID,
          userID: this.props.userID,
          answer: dailyAnswer.answer,
        };
        await API.graphql(graphqlOperation(createDailyAnswer, { input: dailyAnswerToSave }));
      }
      await this.fetchDailyGoals();
      await this.fetchDailyQuestionAnswers(this.state.dailyQuestions);
    } catch (error) {
      console.error("Could not save the correctly update daily reflection on daily goals to the database", error);
      PopupError("Kunde inte updatera dagliga målen till databasen");
    }

    this.setState({ loading: false });
  }
  async DeleteGoal(id) {
    this.setState({ loading: true });
    try {
      await API.graphql({ query: deleteDailyGoal, variables: { input: { id } } });

      await this.fetchDailyGoals();
    } catch (error) {
      console.error("Reset", error);
      PopupError("Kunde inte starta ny dag");
    }

    this.setState({ loading: false });
  }
  async Reset() {
    this.setState({ loading: true });
    try {
      //Remove finished goals
      for (let dailyGoal of this.state.dailyGoals) {
        if (dailyGoal.done) {
          await API.graphql({ query: deleteDailyGoal, variables: { input: { id: dailyGoal.id } } });
        }
      }
      //Remove dailyAnswers
      for (let dailyAnswer of this.state.dailyAnswers) {
        await API.graphql({ query: deleteDailyAnswer, variables: { input: { id: dailyAnswer.answer.id } } });
      }

      await this.fetchDailyGoals();
      await this.fetchDailyQuestionAnswers(this.state.dailyQuestions);
    } catch (error) {
      console.error("Reset", error);
      PopupError("Kunde inte starta ny dag");
    }

    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return <p>Loading</p>;
    }

    if (this.state.step === steps.planing) {
      return (
        <DailyPlaning
          userID={this.props.userID}
          dailyGoals={this.state.dailyGoals}
          dailyQuestions={this.state.dailyQuestions}
          SavePlan={this.SavePlan}
          DeleteGoal={this.DeleteGoal}
        />
      );
    } else if (this.state.step === steps.reflection) {
      return (
        <DailyReflection
          dailyGoals={this.state.dailyGoals}
          dailyQuestions={this.state.dailyQuestions}
          dailyAnswers={this.state.dailyAnswers}
          SaveReflection={this.SaveReflection}
        />
      );
    } else if (this.state.step === steps.show) {
      return <DailyReflectionView dailyGoals={this.state.dailyGoals} dailyAnswers={this.state.dailyAnswers} Reset={this.Reset} />;
    } else {
      return <p>Tomt, Error</p>;
    }
  }
}
export default Daily;
