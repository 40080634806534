import { Roles } from "../Util/Const";

//All urls
export const url = {
  adminForm: "/home/form",
  adminView: "/home/form/show/",
  createForm: "/home/form/new",
  cathegories: "/home/cathergories",
  adminEdit: "/home/form/edit/",
  userAgreementEdit: "/home/form/useragreement/",
  utvecklandeLedande: "/home/utvecklandeLedande/",
  userWeeklyOverview: "/home/userWeeklyOverview/",
  root: "/",
  form: "/form/",
  home: "/home/",
  error: "/error",
  adminCustomerEdit: "/admin/customer/edit",
  adminCustomers: "/admin/customers/",
  adminCustomersUsersEdit: "/admin/customer/users/edit",
  adminCustomersUsers: "/admin/customer/users/",
};
//An url is either part of AuthenticatedUrls or UnauthenticatedUrls
export const AuthenticatedUrls = {
  home: { path: url.home, name: "Hem" },
  adminCustomerEdit: { path: url.adminCustomerEdit, name: "Redigera kund" },
  adminCustomers: { path: url.adminCustomers, name: "KUNDER" },
  adminCustomersUsersEdit: {
    path: url.adminCustomersUsersEdit,
    name: "Redigera Användare",
  },
  adminCustomersUsers: { path: url.adminCustomersUsers, name: "ANVÄNDARE" },
  userAgreementEdit: {
    path: url.userAgreementEdit,
    name: "Redigera Användaravtal",
  },

  daily: { path: url.utvecklandeLedande, name: "UTVECKLANDE LEDARSKAP" },
  error: { path: url.error, name: "Error" },
};

//An url is either part of AuthenticatedUrls or UnauthenticatedUrls
export const UnauthenticatedUrls = {
  landing: { path: url.root, name: "Landing" },
  login: { path: "/login", name: "Logga in" },
  recover: { path: "/recover", name: "Återställ" },
  form: { path: url.form, name: "Livshjul" },
};

//these show up in the navbar when signed in as admin
export const AdminMenuUrls = {
  daily: AuthenticatedUrls.daily,
  adminForm: { path: url.adminForm, name: "LIVSHJULET FORMULÄR" },
  cathegories: { path: url.cathegories, name: "KATEGORIER OCH FRÅGOR" },
  adminCustomers: AuthenticatedUrls.adminCustomers,
};
//these show up in the navbar when signed in as customer admin
export const CustomerAdminMenuUrls = {
  daily: AuthenticatedUrls.daily,
  userOverview: AuthenticatedUrls.adminCustomersUsers,
};
export const UserMenuUrls = {
  daily: AuthenticatedUrls.daily,
};

export function getHomeEquivaletUrlByRole(role) {
  switch (role) {
    case Roles.admin.value:
      return AuthenticatedUrls.adminCustomers.path;
    case Roles.customerAdmin.value:
      return AuthenticatedUrls.adminCustomersUsers.path;
    case Roles.customer.value:
    default:
      return AuthenticatedUrls.daily.path;
  }
}

export function checkAutherized(role, allowedRoles, history) {
  const found = allowedRoles.find((allowedRole) => allowedRole === role);
  if (found==null) {
    //Should not be here, get sent to that role homescreen
    history.push(getHomeEquivaletUrlByRole(role))
    return false;
  }else{
    return true;
  }
}

export function sendHome(role, history){
  history.push(getHomeEquivaletUrlByRole(role))
}