import Swal from "sweetalert2";
import { AddUserToGroup, ListGroupsForUser, RemoveUserFromGroup } from "./AdminQuerries";
import { CognitoUserStatuses, Roles } from "./Const";

export function PopupConfirm(text, confirmCallback) {
  Swal.fire({
    title: text,
    confirmButtonText: "OK",
    showCancelButton: true,
    cancelButtonText: "Avbryt",
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
}

export function PopupWithCallBack(text, confirmCallback) {
  Swal.fire({
    title: text,
    confirmButtonText: "OK",
    showCancelButton: false,
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback();
    }
  });
}

export function PopupError(text) {
  Swal.fire({
    title: text,
    confirmButtonText: "OK",
  });
}
export const ErorrFormMissing = (field) => {
  PopupError("Ogiltigt formulär", field + " saknas från formuläret!");
};


//return true if ok and false if not ok
export const EmailValid = (email) => {
  if (typeof email !== "undefined") {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(email)) {
      return true;
    }
  }
  return false;
};

export const getUserStatusText = (status) => {
  let statusText = "";
  switch (status) {
    case CognitoUserStatuses.confirmed:
      statusText = "Verifierad";
      break;
    case CognitoUserStatuses.unconfirmed:
      statusText = "Ej verifierad";
      break;
    case CognitoUserStatuses.forceChangePassword:
      statusText = "Måste byta lösenord";
      break;
  }
  return statusText;
};

export const updateUserGroup = async (userId, newRole) => {
  try {
    //hämta nuvarande grupp
    let groups = (await ListGroupsForUser(userId))?.Groups;

    //om i grupp finns redan, gå ur
    if (groups?.length > 0) {
      for (let group of groups) {
        await RemoveUserFromGroup(userId, group.GroupName);
      }
    }
    let newGroupName = "";
    //sätt ny grupp.
    switch (newRole) {
      case Roles.admin.value:
        newGroupName= Roles.admin.value;  
      break;
      case Roles.customerAdmin.value:
        newGroupName= Roles.customerAdmin.value;
        break;
      case Roles.customer.value:
        newGroupName= Roles.customer.value;
        break;
      default:
        throw "Invalid role: "+ newRole;
        break;
    }
    await AddUserToGroup(userId, newGroupName);

  } catch (err) {
    console.error("updateUserGroup ", err);
  }
};

export function arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}

export function Clamp(val, min, max){
  return Math.min(Math.max(min, val), max)
}

//Only works with objects that have the order property!
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((item,idx)=>item.order=idx);

  return result;
};
