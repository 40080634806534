import React from "react";
import { Auth } from "aws-amplify";
import Loading from "../../../Components/Loading";
import { AuthenticatedUrls, UnauthenticatedUrls } from "../../../Routes/Urls";
import {
  EmailValid,
  PopupConfirm,
  PopupError,
  PopupWithCallBack,
} from "../../../Util/Util";
import RoundButton from "../../../Components/RoundButton";
import { Grid, TextField } from "@material-ui/core";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, email: "", password: "", error: "" };
    this.validateLogin = this.validateLogin.bind(this);
    this.Login = this.Login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }
  componentDidMount() {
    this.setState({ isLoading: false });
  }

  validateLogin() {
    return (
      this.state.password.length > 7 &&
      this.state.email !== "" &&
      EmailValid(this.state.email)
    );
  }

  async Login(event) {
    event.preventDefault();
    try {
      this.setState({ isLoading: true });
      let user = await Auth.signIn(this.state.email, this.state.password);
      if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.props.history.push({
          pathname: UnauthenticatedUrls.recover.path,
          state: { email: this.state.email, password: this.state.password },
        });
      } else {
        await this.props.userSignIn();
      }
    } catch (err) {
      if (err.code === "UserNotFoundException") {
        PopupError("Användaren finns inte.");
      } else if (err.code === "NotAuthorizedException") {
        PopupError("Fel email eller lösenord");
      } else {
        console.error("Login:", err);
      }
      this.setState({ isLoading: false });
    }
  }
  async forgotPassword() {
    this.setState({ isLoading: true });
    try {
      await Auth.forgotPassword(this.state.email);
      PopupWithCallBack(
        "Ett email har skickats till din adress med en aktiveringskod för att skapa ett nytt lösenord",
        () => {
          this.setState({ isLoading: false });
          this.props.history.push({
            pathname: UnauthenticatedUrls.recover.path,
            state: { email: this.state.email, code: true },
          });
        }
      );
    } catch (error) {
      PopupError("Kunde inte återställa lösenord");
      console.error(error);
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }
    return (
      <Grid container>
        <Grid item xs={10} sm={4}>
          <div>
            <h1>Logga in</h1>
            <div className="utvecklaTextInput">
              <TextField
                variant="standard"
                label="Email"
                placeholder="Ange epostadress"
                fullWidth
                value={this.state.email}
                error={!EmailValid(this.state.email)}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
              />
            </div>
            <div className="utvecklaTextInput">
              <TextField
                variant="standard"
                label="Lösenord"
                type="password"
                placeholder="Ange lösenord"
                fullWidth
                value={this.state.password}
                onChange={(event) =>
                  this.setState({ password: event.target.value })
                }
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <RoundButton
                disabled={!this.validateLogin()}
                onClick={this.Login}
              >
                <p>Logga in</p>
              </RoundButton>
            </div>
            <div style={{ marginTop: "20px" }}>
              <RoundButton
                disabled={!EmailValid(this.state.email)}
                onClick={() =>
                  PopupConfirm("Vill du fortsätta?", this.forgotPassword)
                }
              >
                <p>Gömt lösenord</p>
              </RoundButton>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default Login;
