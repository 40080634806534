import {  Grid, TextField } from "@material-ui/core";
import React, { Component } from "react";
import RoundButton from "../../../../Components/RoundButton";
import { PopupConfirm } from "../../../../Util/Util";

class WeeklyPlaning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekly: this.props.weekly,
    };
    this.UpdateWeekly = this.UpdateWeekly.bind(this);
    this.Validate = this.Validate.bind(this);
  }

  UpdateWeekly(value, key) {
    let weekly = this.state.weekly;
    weekly[key] = value;
    this.setState({ weekly });
  }

  Validate() {
    if (this.state.weekly.goal === "") {
      return false;
    } else if (this.state.weekly.important === "") {
      return false;
    } else if (this.state.weekly.help === "") {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <>
        <h1>Utvecklande Ledarskap</h1>
        <h2>Planering av veckan</h2>

        <Grid container>
          <Grid item xs={12} md={4}>
            <div className="utvecklaTextInput">
              <TextField
                className="textField"
                fullWidth
                value={this.state.weekly.goal}
                onChange={(event) => this.UpdateWeekly(event.target.value, "goal")}
                label="Mål för veckan*"
                variant="filled"
              />
            </div>
            <div className="utvecklaTextInput">
              <TextField
                className="textField"
                fullWidth
                value={this.state.weekly.important}
                onChange={(event) => this.UpdateWeekly(event.target.value, "important")}
                label="Viktigt att tänka på*"
                variant="filled"
              />
            </div>
            <div className="utvecklaTextInput">
              <TextField
                className="textField"
                fullWidth
                value={this.state.weekly.help}
                onChange={(event) => this.UpdateWeekly(event.target.value, "help")}
                label="Vad behöver jag hjälp med?*"
                variant="filled"
              />
            </div>
          </Grid>
        </Grid>

        <RoundButton disabled={!this.Validate()} onClick={() => PopupConfirm("Är du nöjd med din planerade vecka?", () => this.props.Save(this.state.weekly))}>
          <p>Spara</p>
        </RoundButton>
      </>
    );
  }
}
export default WeeklyPlaning;
