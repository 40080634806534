import { API } from "aws-amplify";
 
export async function SaveOrUpdate(item, dbUpdate, dbCreate) {
  try {
    if (item.id != null) {
      //uppdatera
      await API.graphql({ query: dbUpdate, variables: { input: item } });
    } else {
      //Spara
      await API.graphql({ query: dbCreate, variables: { input: item } });
    }
    return true;
  } catch (err) {
    console.error("Cound not save or update", item, " error", err);
    return false;
  }
}
 
//Todo: write custom resolver in graphql
export async function GetItemByProperties(properties, dbList) {
  try {
    let items = (await API.graphql({ query: dbList }))?.data;
    items = Object.values(items)[0].items;
    //filter so the object matches all the properties provided.
    for (let property in properties) {
      items = items.filter((item) => item[property] === properties[property]);
    }
    return items;
  } catch (err) {
    console.error("Cound not get by properties ", properties, " error", err);
    return null;
  }
}
 
export async function GetItemById(id, dbGet) {
  try {
    let item = (await API.graphql({ query: dbGet, variables: {id:id } }))?.data;
    return Object.values(item)[0];
  } catch (err) {
    console.error("Cound not get by id ", id, " error", err);
    return null;
  }
}