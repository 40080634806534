import React from "react";
import {
  AdminMenuUrls,
  CustomerAdminMenuUrls,
  UserMenuUrls,
} from "../Routes/Urls";
import logo from "../Assets/Images/mona-wassermann-logo-vit.png";
import { Grid } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Roles } from "../Util/Const";
import RoundButton from "./RoundButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0, showMenu: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.navigateAndClose = this.navigateAndClose.bind(this);
    // this.navigate = this.navigate.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  navigateAndClose(url) {
    // this.setState({ showMenu: false });
    // this.props.app.history.push(url);
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
      this.props.app.history.push(url);
    } 
    // else {
    //   this.props.app.history.push(url);
    // }
  }
  navigate(url) {
    this.props.app.history.push(url);
  }

  render() {
    let links = [];
    switch (this.props.app.role) {
      case Roles.admin.value:
        links = Object.values(AdminMenuUrls);
        break;
      case Roles.customerAdmin.value:
        links = Object.values(CustomerAdminMenuUrls);
        break;
      case Roles.customer.value:
        links = Object.values(UserMenuUrls);
        break;
      default:
        links = [];
        break;
    }
    return (
      <div className="navigationMenu">
        <Grid container justify="space-between">
          <Grid item xs={9} md={4}>
            <img
              style={{
                display: "inline",
                width: "100%",
                height: "100%",
                maxWidth: "400px",
                maxHeight: "114px",
              }}
              src={logo}
              alt="Logo"
            />
          </Grid>
          {this.state.width <= 1200 && (
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginRight: "30px",
              }}
              onClick={() => this.setState({ showMenu: !this.state.showMenu })}
            >
              <MenuIcon
                htmlColor="white"
                fontSize="large"
                style={{ paddingLeft: "30px" }}
              />
            </Grid>
          )}
          {this.state.width > 1200 && (
            <Grid item xs={8}>
              <ul style={{ display: "flex", alignItems: "center" }}>
                {links.map((url) => (
                  <div
                    style={{ cursor: "default" }}
                    key={url.path}
                    onClick={() => this.navigate(url.path)}
                  >
                    <NavbarLink url={url} />
                  </div>
                ))}
                <li
                  style={{
                    display: "inline",
                    margin: "10px",
                    paddingLeft: "20px",
                  }}
                >
                  <RoundButton onClick={() => this.props.app.userSignOut()}>
                    <ExitToAppIcon />
                    <p>Logga ut</p>
                  </RoundButton>
                </li>
              </ul>
            </Grid>
          )}
        </Grid>

        {this.state.width <= 1200 && this.state.showMenu && (
          <div className="navMenuDropdown">
            <ul
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingLeft: "0px",
              }}
            >
              {links.map((url) => (
                <div
                  key={url.path}
                  onClick={() => this.navigateAndClose(url.path)}
                >
                  <NavbarLink url={url} />
                </div>
              ))}
              <li
                style={{
                  display: "inline",
                  margin: "10px",
                  // paddingLeft: "20px",
                }}
              >
                <RoundButton onClick={() => this.props.app.userSignOut()}>
                  <ExitToAppIcon />
                  <p>Logga ut</p>
                </RoundButton>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const NavbarLink = ({ url }) => {
  return (
    // <li style={{ display: "inline", margin: "25px"}}>
    <li
      className="mainMenu"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: "25px",
      }}
    >
      <p> {url.name}</p>
    </li>
  );
};
// const NavbarLink = ({ url }) => {
//   return (
//     <li
//       className="mainMenu"
//       style={{
//         display: "flex",
//         flexDirection: "row",
//         alignItems: "center",
//         margin: "25px",
//       }}
//     >
//       <Link onClick={()=>this.navigateAndClose(url.path)}>{url.name}</Link>
//     </li>
//   );
// };

export default Navbar;
