import { API, graphqlOperation } from 'aws-amplify';
import React, { Component } from 'react'
import { updateQuestion } from '../../../../graphql/mutations';
import { Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { PopupConfirm } from '../../../../Util/Util';

// const initialState= {questionObj: {}, question:'', canEdit: false}

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionText: props.question.question,
            isArchived: props.question.isArchived,
            edit: false,
            order: props.question?.order || 0,
        }
        this.editQuestion = this.editQuestion.bind(this);
        this.deleteCathegoryQuestion = this.deleteCathegoryQuestion.bind(this)
        this.cancel = this.cancel.bind(this);
    }

    //When pressing the edit button. To enable editable items
    async editQuestion() {
        if (this.state.edit) {
            try {
                const newQuestion = { ...this.props.question, question: this.state.questionText, order: this.state.order }
                const updatedQuestion =
                {
                    id: newQuestion.id,
                    cathegoryID: newQuestion.cathegoryID,
                    question: newQuestion.question,
                    isArchived: newQuestion.isArchived,
                    order: newQuestion.order,
                }
                await API.graphql(graphqlOperation(updateQuestion, { input: updatedQuestion }))
                this.setState({ edit: false })
                this.props.reload();
            } catch (err) {
                window.alert("Kunde inte spara frågan")
                console.error('error when updating question', err)
            }
        } else {
            this.setState({ edit: true })
        }
    }
    cancel() {
        this.setState({ edit: false, questionText: this.props.question.question })
    }

    async deleteCathegoryQuestion() {
        if (!this.state.edit) {
            try {
                const newQuestion = { ...this.props.question}
                const updatedQuestion =
                {
                    id: newQuestion.id,
                    cathegoryID: newQuestion.cathegoryID,
                    question: newQuestion.question,
                    isArchived: true,
                }
                await API.graphql(graphqlOperation(updateQuestion, { input: updatedQuestion }))
                this.setState({isArchived: true});
                this.props.reload();
            } catch (error) {
                console.error("Kunde inte ta bort frågan", error)
            }
        }
    }

    render() {
        return (
            <div className="tableRow">
                <div style={{maxWidth: "70%"}}>
                {!this.state.edit && <p>{this.state.questionText}</p>}
                {this.state.edit &&
                    <input id={this.props.question.id}
                        className="tableRowInput"
                        value={this.state.questionText}
                        placeholder={this.props.question.question}
                        onChange={event => this.setState({ questionText: event.target.value })}
                    ></input>}
                    {this.state.edit &&
                    <input id={this.props.question.id}
                        className="tableRowInput"
                        value={this.state.order}
                        placeholder={this.props.question.order}
                        onChange={event => this.setState({ order: event.target.value })}
                    ></input>}
                </div>
                <div>
                    {this.state.edit && <Button startIcon={<SaveIcon/>} variant="contained" color="default" onClick={this.editQuestion} >Save</Button>}
                    {!this.state.edit && <Button startIcon={<EditIcon/>} variant="contained" color="default" onClick={this.editQuestion} >Redigera</Button>}
                    {!this.state.edit && <Button startIcon={<DeleteIcon/>} variant="contained" color="default" onClick={()=> PopupConfirm("Är du säker på att du vill ta bort frågan?", ()=> this.deleteCathegoryQuestion())}>Ta bort</Button>}                    
                    {this.state.edit && <Button startIcon={<CloseIcon/>} variant="contained" color="default" onClick={this.cancel} >Avbryt</Button>}
                </div>
            </div>);
    }
}

export default Question; 
