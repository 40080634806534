import React from "react";
import { MWGreen } from "../Util/Const";

//A button which performs the "onClick-function"
class IconButton extends React.Component {
  render() {
    return (
      <div
        onClick={this.props?.disabled ? null : this.props.onClick}
        className="roundButton"
        style={{
          backgroundColor: MWGreen,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "5px",
          paddingRight: "10px",
          paddingBottom: "5px",
          paddingLeft: "10px",

          color: "#ffff",

          borderRadius: "100px",
          borderWidth: "3px",
          borderColor: MWGreen,
          borderStyle: "solid",
          textShadow: "0em 0.1em 0.1em rgb(0 0 0 / 5%)",
          boxShadow: "0px 2px 18px 0px rgb(0 0 0 / 15%)",
          cursor: "default",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default IconButton;
