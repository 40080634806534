import React from "react";
//Breaks text into new paragraph after each newline
class NewlineText extends React.Component {
  render() {
    return (
      <>
        {this.props?.text.split("\n").map((text, idx) => (
          <p key={("newlineText-", idx)}>{text}</p>
        ))}
      </>
    );
  }
}
 
export default NewlineText;