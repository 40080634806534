import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import RoundButton from "../../../../Components/RoundButton";
import { listWeeklys } from "../../../../graphql/queries";
import { PopupError } from "../../../../Util/Util";
import WeeklyReflectionShow from "../../../CheckInOut/Steps/Weekly/WeeklyReflectionShow";
import { Roles } from "../../../../Util/Const";
import { checkAutherized, sendHome } from "../../../../Routes/Urls";

const acceptedRoles = [Roles.admin.value, Roles.customerAdmin.value]; //Global admin and customer admin

class UserWeeklyOverview extends React.Component {
  constructor(props) {
    super(props);
    let noError= true;
    if(props.location?.state?.user == null){
      sendHome(props.role, props.history)
      noError= false
    }
    this.state = {
      //So user can not use the URL and bypass router authentication
      autherized: checkAutherized(props.role, acceptedRoles, props.history) && noError,
      user: this.props.location.state?.user,
      weekly: null,
      loading: true,
    };
    this.fetchWeekly = this.fetchWeekly.bind(this);
  }

  async componentDidMount() {
    if (this.state.autherized) {
      await this.fetchWeekly();
      this.setState({ loading: false });
    }
  }

  async fetchWeekly() {
    try {
      let weeklys = (await API.graphql(graphqlOperation(listWeeklys)))?.data
        ?.listWeeklys?.items;

      let weekly = weeklys.filter(
        (weekly) => weekly.userID === this.state.user.id
      );
      if (weekly.length === 1) {
        weekly = weekly[0];
      } else {
        weekly = null;
      }

      this.setState({ weekly, loading: false });
    } catch (error) {
      console.error(error);
      PopupError("Kunde inte hämta veckomål");
    }
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    if (this.state.loading) {
      return <p>Loading</p>;
    }
    return (
      <>
        <h1>Sammanfattning av utvecklande ledarskap</h1>
        <h2> {this.state.user.name}</h2>

        {this.state.weekly != null && (
          <WeeklyReflectionShow weekly={this.state.weekly} readOnly={true} />
        )}
        {this.state.weekly == null && <p>Tomt</p>}
        <div style={{ display: "flex", marginTop: "40px" }}>
          <RoundButton
            style={{ marginRight: "20px" }}
            onClick={() => this.props.history.goBack()}
          >
            <p>Gå tillbaka</p>
          </RoundButton>
        </div>
      </>
    );
  }
}

export default UserWeeklyOverview;
