import React from "react";

class Loading extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   componentDidMount() {}

  render() {
    return (
      <>
        <h1>Loading</h1>
      </>
    );
  }
}

export default Loading;
