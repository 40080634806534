import uuid from "react-uuid";
const { Storage } = require("aws-amplify");

export const StorageFolders = {
  images: "images/",
};

export async function getPathFromStorage(path) {
  const url = await Storage.get(path);
  return url;
}

export async function deleteFile(filePath) {
  try {
    await Storage.remove(filePath, { level: "public" });
    return true;
  } catch (error) {
    console.error("error uploading material: ", error);
  }
  return false;
}

export async function uploadImage(file) {
  const path = StorageFolders.images + uuid() + "." + file.name.split(".").pop();
  try {
    await Storage.put(path, file, { level: "public" });
    return path;
  } catch (error) {
    console.error("error uploading image: ", error);
    return null;
  }
}

export async function getImage(imagePath) {
  try {
    let list = await Storage.list("",{ level: "public" });
    if (list.find((file) => file.key === imagePath) != null) {
      let url = await Storage.get(imagePath);
      return url;
    }
    return null;
  } catch (err) {
    console.error("Getimage", err);
  }
}
