import { Grid, Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import { Component } from "react";
import { MWGreen } from "../../Util/Const";
import Daily from "./Steps/Daily/Daily";
import Weekly from "./Steps/Weekly/Weekly";
import { checkAutherized } from "../../Routes/Urls";
import { Roles } from "../../Util/Const";

const MWGreenTabs = withStyles({
  indicator: {
    backgroundColor: MWGreen,
    height: "4px",
  },
})(Tabs);

const acceptedRoles = [Roles.admin.value, Roles.customerAdmin.value, Roles.customer.value]; //Global admin & customer admin & user(customer)

const steps = { planing: "1", reflection: "2" };
class CheckInOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autherized: checkAutherized(props.app.role, acceptedRoles, props.history),
      daily: false,
      weekly: true,
      step: steps.planing,
      currentTab: 0,
    };
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    return (
      <div style={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <MWGreenTabs value={this.state.currentTab} onChange={(event, value) => this.setState({ currentTab: value })}>
              <Tab label="Daglig" />
              <Tab label="Vecka" />
            </MWGreenTabs>
          </Grid>
          <Grid item xs={12} lg={12}>
            {this.state.currentTab === 0 && <Daily userID={this.props.app.userID} />}
            {this.state.currentTab === 1 && <Weekly userID={this.props.app.userID} />}
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default CheckInOut;
