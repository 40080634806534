import { Card, Grid, TextField } from "@material-ui/core";
import React, { Component } from "react";
import RoundButton from "../../../../Components/RoundButton";
import { PopupConfirm, PopupError } from "../../../../Util/Util";

class DailyReflection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Reflected uppon goals
      dailyGoals: this.props.dailyGoals,
      //The users' answers to the daily reflection questions
      reflectionAnswers: [],
    };
    this.save = this.save.bind(this);
    this.UpdateDailyGoal = this.UpdateDailyGoal.bind(this);
    this.Validate = this.Validate.bind(this);
  }

  // To retrieve already existing values in the DB for goal reflection
  dailyReflectionTextField(goalID, key) {
    var goal = this.props.dailyGoals.find((goal) => goal.id === goalID);
    if (goal && key in goal) {
      return goal[key];
    } else {
      console.error("Could not retrieve daily goal key ", key, " for dailyGoalID ", goalID);
      PopupError("Kunde inte hämta målet");
      return "";
    }
  }
  //To retrieve already existing values in the DB for reflection question
  getDailyQuestionAnswerfromDB(dailyQuestionID) {
    var answer = this.props.dailyAnswers.find((answer) => answer.dailyQuestionID === dailyQuestionID);
    if (!answer) {
      return "";
    } else {
      return answer.answer;
    }
  }

  UpdateDailyGoal(value, id, key) {
    let updatedDailyGoals = this.state.dailyGoals;
    let updatedDailyGoal = updatedDailyGoals.find((goal) => goal.id === id);

    if (updatedDailyGoal) {
      updatedDailyGoal[key] = value;
      this.setState({
        updatedDailyGoals,
      });
    } else {
      console.error("UpdateDailyGoal in reflection", id, key, value);
      PopupError("Error");
    }
  }

  //To keep track of the userinput in the different TextFields, for daily question reflection
  //Answer the question
  addDailyQuestionToList(answer, dailyQuestionID) {
    let newDailyQuestionAnswers = this.state.reflectionAnswers;
    let newDailyQuestionAnswer = newDailyQuestionAnswers.find((answer) => answer.dailyQuestionID === dailyQuestionID);

    if (!newDailyQuestionAnswer) {
      newDailyQuestionAnswers.push({
        answer: answer,
        dailyQuestionID: dailyQuestionID,
      });
    } else {
      newDailyQuestionAnswer.answer = answer;
    }
    this.setState({
      reflectionAnswers: newDailyQuestionAnswers,
    });
  }

  async save() {
    this.props.SaveReflection(this.state.dailyGoals, this.state.reflectionAnswers);
  }

  // Reflection questions must be answered
  Validate() {
    if (this.state.reflectionAnswers.length < 1) {
      return false;
    }
    for (let reflectionAnswer of this.state.reflectionAnswers) {
      if (reflectionAnswer.answer === "") {
        return false;
      }
    }
    return true;
  }

  render() {
    return (
      <>
        <h1>Utvecklande Ledarskap</h1>
        <h2>Daglig reflektion</h2>

        <Grid container spacing={3}>
          {this.state.dailyGoals.map((dailyGoal) => (
            <Grid container item xs={12} md={4} key={dailyGoal.id}>
              <Card style={{ width: "100%", padding: "20px" }}>
                <Grid item xs={12}>
                  <h2>{dailyGoal.toDo}</h2>
                </Grid>
                <Grid item xs={12} className="gridInListStyle">
                  <TextField
                    className="textField"
                    onChange={(event) => this.UpdateDailyGoal(event.target.value, dailyGoal.id, "goodReflection")}
                    value={dailyGoal.goodReflection || ""}
                    label="Vad gick bra?"
                    variant="filled"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className="gridInListStyle">
                  <TextField
                    className="textField"
                    onChange={(event) => this.UpdateDailyGoal(event.target.value, dailyGoal.id, "badReflection")}
                    value={dailyGoal.badReflection || ""}
                    label="Vad gick mindre bra?"
                    variant="filled"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className="gridInListStyle">
                  <TextField
                    className="textField"
                    onChange={(event) => this.UpdateDailyGoal(event.target.value, dailyGoal.id, "lessonLearned")}
                    value={dailyGoal.lessonLearned || ""}
                    label="Vad har jag lärt mig?"
                    variant="filled"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="gridInListStyle"
                >
                  <p style={{marginRight: "10px"}}>Mål klart</p>
                  <input
                    className="selectFormCheck"
                    type="checkbox"
                    onClick={() => this.UpdateDailyGoal(!dailyGoal.done, dailyGoal.id, "done")}
                    value={dailyGoal.done}
                  />
                </Grid>
              </Card>
            </Grid>
          ))}

          <Grid container item xs={12}>
            <Card style={{ width: "100%", padding: "20px" }}>
              {this.props.dailyAnswers.map(
                (x) =>
                  !x.question.isReflection && (
                    <Grid item xs={12} className="gridInListStyle" key={x.answer.id}>
                      <p>
                        <b>{x.question.Question}</b> <br />
                        {x.answer.answer}
                      </p>
                    </Grid>
                  )
              )}
              {this.props.dailyQuestions.map(
                (dailyQuestion) =>
                  dailyQuestion.isReflection && (
                    <Grid item xs={12} md={4} className="gridInListStyle" key={dailyQuestion.id}>
                      <TextField
                        className="textField"
                        fullWidth
                        onChange={(event) => this.addDailyQuestionToList(event.target.value, dailyQuestion.id)}
                        value={dailyQuestion.answer}
                        label={dailyQuestion.Question + "*"}
                        variant="filled"
                      />
                    </Grid>
                  )
              )}
            </Card>
          </Grid>
          <Grid item>
            <RoundButton disabled={!this.Validate()} onClick={() => PopupConfirm("Vill du spara?", this.save)}>
              <p>Spara</p>
            </RoundButton>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default DailyReflection;
