import React from "react";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core";
import { MWGreen, starColor } from "../Util/Const";

const StyledRating = withStyles({
  iconFilled: {
    color: starColor,
  },
  iconHover: {
    color: starColor,
  },
  iconEmpty: {
  }
})(Rating);

export default function StarRating(props) {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div>
        <StyledRating
          size= {props?.size || "medium"}
          name={"start-rating" + props?.id}
          value={props.stars}
          max = {props?.max || 5}
          onChange={(event, newValue) => {
            props.onChange(newValue);
          }}
          readOnly={props.disabled}
        />
      </div>
      {!props.disabled && props?.noClear==null &&(
        <div
          style={{ padding: "5px" }}
          onClick={() => {
            !props.disabled && props.onChange(0);
          }}
        >
          <p>(rensa)</p>
        </div>
      )}
    </div>
  );
}