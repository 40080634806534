import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthenticatedUrls, getHomeEquivaletUrlByRole } from "./Urls";

export default ({ component: C, props: cProps, ...rest }) => {
  
return(
  <Route
    {...rest}
    render={(props) =>
      !cProps.app.isAuthenticated ? (
        <C {...props} {...cProps} />
      ) : (
        <Redirect to={getHomeEquivaletUrlByRole(cProps.app.role)} />
      )
    }
  />
);
  }