import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getForm, getCathegory } from "../../../graphql/queries";
import { url } from "../../../Routes/Urls";
import Victory from "../../UserForm/Components/Victory";
import { checkAutherized, sendHome } from "../../../Routes/Urls";
import { Roles } from "../../../Util/Const";

const acceptedRoles = [Roles.admin.value]; //Global admin

class FormView extends Component {
  constructor(props) {
    super(props);
    let formID;
    let noError = true;
    if (props?.location?.state?.formID) {
      formID = props.location.state.formID;
    } else if (props?.formID) {
      formID = props.formID;
    } else {
      sendHome(props.role, props.history);
      console.error("Could not load resources");
      noError = false;
    }
    this.state = {
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      formID,
      form: "",
      answers: [],
      cathegories: [],
    };
    this.fetchForm = this.fetchForm.bind(this);
    this.getFormInformation = this.getFormInformation.bind(this);
  }
  componentDidMount() {
    if (this.state.autherized) {
      this.fetchForm();
    }
  }

  //Where we fetch the selected user form
  async fetchForm() {
    try {
      const tempForm = (
        await API.graphql(graphqlOperation(getForm, { id: this.state.formID }))
      )?.data.getForm;
      this.setState({ form: tempForm });
      this.getFormInformation(tempForm);
    } catch (err) {
      console.error("error fetching form;", err);
    }
  }
  //Retrieve the cathegories in the from
  async getFormInformation(form) {
    let tempCat = form.cathegories.items;
    //Sort cathegories based on the order selected when form was created
    tempCat.sort((a, b) =>
      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
    );

    let cathegories = [];
    for (let i = 0; i < tempCat.length; i++) {
      let cathegory = await this.getCathegorybyID(tempCat[i].cathegoryID);
      cathegory.questions.items = cathegory.questions.items.filter(
        (quest) =>
          null !=
          form.answers.items.find((answer) => answer.questionID === quest.id)
      );
      cathegories.push(cathegory);
    }
    this.setState({ cathegories: cathegories });
    this.setState({ answers: form.answers.items });
  }

  async getCathegorybyID(id) {
    return (await API.graphql(graphqlOperation(getCathegory, { id })))?.data
      ?.getCathegory;
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    return (
      <>
        <h1>Sammanfattning livshjul formulär</h1>
        <Victory cathegories={this.state.cathegories} form={this.state.form} />
      </>
    );
  }
}
export default FormView;
