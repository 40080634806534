import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  TextField,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { API, Auth, graphqlOperation } from "aws-amplify";
import {
  EmailValid,
  PopupConfirm,
  PopupError,
  updateUserGroup,
} from "../../../../Util/Util";
import { Roles } from "../../../../Util/Const";
import {
  AdminCreateUser,
  GetUser,
  ListGroupsForUser,
  DisableUser,
  DeleteUser as DeleteCognitoUser,
  EnableUser,
  ConfirmUserSignUp,
} from "../../../../Util/AdminQuerries";
import {
  createUser,
  deleteDailyAnswer,
  deleteDailyGoal,
  deleteUser,
  deleteWeekly,
} from "../../../../graphql/mutations";
import {
  listWeeklys,
  listDailyGoals,
  listDailyAnswers,
} from "../../../../graphql/queries";
import { checkAutherized } from "../../../../Routes/Urls";

const initialForm = {
  name: "",
  email: "",
  agreedToLeagalTerms: "false",
  role: Roles.customer.value,
};
const errorCheckList = {
  //nyckel - > feltext som ska visas.
  name: "Namn",
  email: "Email",
};
const acceptedRoles = [Roles.admin.value]; //Global admin

class SectionUsersEdit extends React.Component {
  constructor(props) {
    super(props);

    const userId = props.location.state?.userId;
    this.state = {
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      edit: userId != null,
      customer: props.location.state?.customer,
      userId,
      user: { ...initialForm },
      // editProfile: props.location.state?.editProfile != null,
      roles:
        this.props?.role === "Admins" ? Object.values(Roles) : [Roles.customer],
      loading: true,
    };
    this.UpdateUser = this.UpdateUser.bind(this);

    this.FetchUser = this.FetchUser.bind(this);
    this.Save = this.Save.bind(this);
    this.Delete = this.Delete.bind(this);
    this.Cancel = this.Cancel.bind(this);
  }

  async componentDidMount() {
    if (this.state.autherized) {
      if (this.state.userId != null) {
        await this.FetchUser();
      }
      this.setState({ loading: false });
    }
  }

  async FetchUser() {
    try {
      let user = await GetUser(this.state.userId);
      let groups = (await ListGroupsForUser(this.state.userId))?.Groups;
      this.setState({
        user: {
          id: this.state.userId,
          email:
            user.UserAttributes.find((a) => a.Name === "email")?.Value || "",
          name: user.UserAttributes.find((a) => a.Name === "name")?.Value || "",
          role: groups[0]?.GroupName,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  UpdateUser(key, value) {
    let user = this.state.user;
    user[key] = value;
    this.setState({ user });
  }

  async Delete() {
    try {
      //Delete specific userdata, the weekly datamodel.
      let weeklys = (await API.graphql(graphqlOperation(listWeeklys)))?.data
        ?.listWeeklys?.items;
      let weeklyIDsToDelete = weeklys
        .filter((weekly) => weekly.userID === this.state.user.id)
        .map((weekly) => weekly.id);
      for (let weeklyID of weeklyIDsToDelete) {
        await API.graphql({
          query: deleteWeekly,
          variables: { input: { id: weeklyID } },
        });
      }

      //Delete specific userdata, the dailygoal datamodel.
      let dailyGoals = (await API.graphql(graphqlOperation(listDailyGoals)))
        ?.data?.listDailyGoals?.items;
      let dailyGoalIDsToDelete = dailyGoals
        .filter((dailyGoal) => dailyGoal.userID === this.state.user.id)
        .map((dailyGoal) => dailyGoal.id);
      for (let dailyGoalID of dailyGoalIDsToDelete) {
        await API.graphql({
          query: deleteDailyGoal,
          variables: { input: { id: dailyGoalID } },
        });
      }

      //Delete specific userdata, the dailyAnswer datamodel.
      let dailyAnswer = (await API.graphql(graphqlOperation(listDailyAnswers)))
        ?.data?.listDailyAnswers?.items;
      let dailyAnswerIDsToDelete = dailyAnswer
        .filter((dailyAnswer) => dailyAnswer.userID === this.state.user.id)
        .map((dailyAnswer) => dailyAnswer.id);
      for (let dailyAnswer of dailyAnswerIDsToDelete) {
        await API.graphql({
          query: deleteDailyAnswer,
          variables: { input: { id: dailyAnswer } },
        });
      }
      //Delete user
      await DisableUser(this.state.user.id);
      await DeleteCognitoUser(this.state.user.id);
      await API.graphql({
        query: deleteUser,
        variables: { input: { id: this.state.user.id } },
      });
    } catch (err) {
      try {
        await EnableUser(this.state.user.id);
      } catch (err2) {
        console.error("could not restore failed Delete of user: ", err2);
      }
      console.error("Delete User: ", err);
    }
    this.props.history.goBack();
  }

  async Cancel() {
    this.props.history.goBack();
  }

  async Save() {
    this.setState({ loading: true });
    try {
      let newUser = {};
      //Copy only the props from the form and add id to deal with updating.
      for (let key of Object.keys({ ...initialForm, id: null })) {
        newUser[key] = this.state.user[key];
      }

      let userid = null;
      if (newUser.id) {
        //Todod
        userid = newUser.id;
      } else {
        let result = await AdminCreateUser({
          name: newUser.name,
          email: newUser.email,
        });
        userid = result.result.User.Username;
        await API.graphql({
          query: createUser,
          variables: {
            input: { id: userid, customerID: this.state.customer.id },
          },
        });
      }

      await updateUserGroup(userid, newUser.role);

      this.props.history.goBack();
    } catch (err) {
      PopupError("Fel, gick inte att spara.");
      console.error("error: ", err);
    }
  }
  //return true if valid and false if not valid.
  Validate() {
    let tempUser = {};
    for (let key of Object.keys({ ...initialForm })) {
      tempUser[key] = this.state.user[key];
    }
    for (let key of Object.keys(errorCheckList)) {
      if (tempUser[key] === initialForm[key]) {
        return false;
      }
    }
    if (!EmailValid(tempUser.email)) {
      return false;
    }
    return true;
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    if (this.state.loading) {
      return <p>Loading</p>;
    }
    return (
      <>
        <Paper style={{ display: "flex", padding: "20px" }}>
          <div>
            {this.state.edit && <h1>Redigera användare</h1>}
            {!this.state.edit && <h1>Skapa ny användare</h1>}
            {this.state.edit && (
              <>
                <p>{this.state.user.name || "Namn saknas"}</p>
                <p>{this.state.user.email || "Email saknas"}</p>
              </>
            )}
            {!this.state.edit && (
              <TextField
                className="textField"
                label="Namn *"
                value={this.state.user.name}
                onChange={(x) => this.UpdateUser("name", x.target.value)}
              />
            )}

            {!this.state.edit && (
              <TextField
                label="Email *"
                value={this.state.user.email}
                error={!EmailValid(this.state.user.email)}
                onChange={(x) => this.UpdateUser("email", x.target.value)}
                fullWidth
              />
            )}
            <FormControl component="fieldset" style={{ marginTop: "20px" }}>
              <RadioGroup
                row
                value={this.state.user.role}
                onChange={(event) =>
                  this.UpdateUser("role", event.target.value)
                }
              >
                {this.state.roles.map((role) => (
                  <FormControlLabel
                    key={role.value}
                    value={role.value}
                    control={<Radio color="primary" />}
                    label={role.displaValue}
                    labelPlacement="top"
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button onClick={this.Cancel}>
                <h4>Avbryt</h4>
              </Button>
              {this.state.edit && (
                <Button
                  color="primary"
                  onClick={() =>
                    PopupConfirm("Vill du ta bort användaren?", () =>
                      this.Delete()
                    )
                  }
                >
                  <h4>Ta bort</h4>
                </Button>
              )}
              <Button
                color="primary"
                onClick={this.Save}
                disabled={!this.Validate()}
              >
                <h4>Spara</h4>
              </Button>
            </div>
          </div>
        </Paper>
      </>
    );
  }
}

export default SectionUsersEdit;
