import { Card, Grid } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React from "react";
import { Component } from "react";
import StarRating from "../../../Components/StarRating";

class Victory extends Component {
  render() {
    return (
      <>
        <Grid container>
          <Grid item container xs={12} md={4} spacing={3}>
            <Grid item xs={12}>
              <Card style={{ padding: "20px" }}>
                <p>
                  <b>A</b>: Hur upplevs detta område idag?
                </p>
                <p>
                  <b>B</b>: Vilken ambitionsnivå har jag idag?
                </p>
                <p>
                  <b>C</b>: Graden av prioriteringsvilja?
                </p>
                {this.props?.showText && (
                  <>
                    <p>
                      Nu är du klar med Livsjhulet och har fått skriva ner dina
                      ambitioner och prioriteringar. När du nu ser det framför
                      dig, är det något du vill ändra på?
                    </p>
                    <p>
                      Ta en stund för dig själv att reflektera kring det du
                      skrivit.
                    </p>
                  </>
                )}
              </Card>
            </Grid>
            {this.props.cathegories
              .sort((a, b) =>
                a.order > b.order ? 1 : b.order > a.order ? -1 : 0
              )
              .map((cathegory) => (
                <Grid item xs={12} key={cathegory.id}>
                  <Card style={{ padding: "20px" }}>
                    <h3>
                      <b>{cathegory.cathegory}</b>
                    </h3>
                    {cathegory.questions.items
                      // .filter((q) => q.isArchived === false)
                      .map((question) => {
                        let answer = this.props.form.answers.items.find(
                          (answer) => answer.questionID === question.id
                        );
                        if (answer == null) {
                          return null;
                        }
                        return (
                          <div key={question.id}>
                            <p>
                              <b>{question.question}</b>
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ paddingRight: "20px" }}>
                                A <br /> Idag
                              </p>
                              <StarRating
                                id={answer.id + "today"}
                                stars={answer.today}
                                max={10}
                                size="medium"
                                disabled={true}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ paddingRight: "20px" }}>
                                B <br /> Vill
                              </p>
                              <StarRating
                                id={answer.id + "want"}
                                stars={answer.want}
                                max={10}
                                size="medium"
                                disabled={true}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ paddingRight: "20px" }}>
                                C <br /> Prio
                              </p>
                              <StarRating
                                id={answer.id + "priority"}
                                stars={answer.priority}
                                max={10}
                                size="medium"
                                disabled={true}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </>
    );
  }
}
export default Victory;
