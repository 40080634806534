import React, { Component } from "react";

import { API, graphqlOperation } from "aws-amplify";
import { listWeeklys } from "../../../../graphql/queries";
import { updateWeekly, createWeekly } from "../../../../graphql/mutations";
import { PopupError } from "../../../../Util/Util";
import { SaveOrUpdate } from "../../../../Util/API";
import WeeklyPlaning from "./WeeklyPlaning";
import WeeklyReflection from "./WeeklyReflection";
import WeeklyReflectionShow from "./WeeklyReflectionShow";

//mathches DB table
const model = {
  goal: "",
  important: "",
  help: "",
  strategyWorkOut: 0,
  good: "",
  bad: "",
  solution: "",
  reflection: "",
};

const steps = { planing: "1", show: "2", reflection: "3" };

class Weekly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekly: model,
      step: steps.planing,
      loading: true,
    };
    this.fetchWeekly = this.fetchWeekly.bind(this);
    this.Save = this.Save.bind(this);
    this.Reset = this.Reset.bind(this);
  }

  async componentDidMount() {
    await this.fetchWeekly();
    this.setState({ loading: false });
  }

  async fetchWeekly() {
    try {
      let weeklys = (await API.graphql(graphqlOperation(listWeeklys)))?.data
        ?.listWeeklys?.items;

      let weekly = weeklys.filter(
        (weekly) => weekly.userID === this.props.userID
      );
      if (weekly.length === 1) {
        weekly = weekly[0];
        weekly = {
          id: weekly.id,
          userID: weekly.userID,
          goal: weekly.goal,
          important: weekly.important,
          help: weekly.help,
          strategyWorkOut: weekly.strategyWorkOut,
          good: weekly.good,
          bad: weekly.bad,
          solution: weekly.solution,
          reflection: weekly.reflection,
        };
      } else {
        if (weekly.length > 1) {
          console.error("More than one weekly, 1 was expected");
          weekly = weekly[0];
          weekly = {
            id: weekly.id,
            userID: weekly.userID,
            goal: weekly.goal,
            important: weekly.important,
            help: weekly.help,
            strategyWorkOut: weekly.strategyWorkOut,
            good: weekly.good,
            bad: weekly.bad,
            solution: weekly.solution,
            reflection: weekly.reflection,
          };
        }
        //Vi hade inget fall som hanterade första gången en användare skapar en vecka!(so made this)
        else if (weekly.length === 0) {
          weekly = {
            userID: "",
            goal: "",
            important: "",
            help: "",
            strategyWorkOut: 0,
            good: "",
            bad: "",
            solution: "",
            reflection: "",
          };
        } else {
          weekly = null;
        }
      }
      let step = steps.planing;
      if (weekly != null && weekly.goal !== "") {
        step = steps.show;
      }
      this.setState({ weekly, step });
    } catch (error) {
      console.error(error);
      PopupError("Kunde inte starta Veckomålsvyn");
    }
  }

  //Save plannmign
  async Save(weekly) {
    try {
      this.setState({ loading: true });
      if (weekly != null) {
        weekly = { ...weekly, userID: this.props.userID };
        if (this.state.weekly?.id != null) {
          weekly = { ...weekly, id: this.state.weekly.id };
        }
        await SaveOrUpdate(weekly, updateWeekly, createWeekly);
      }

      await this.fetchWeekly();
    } catch (error) {
      console.error("Weekly.js save:", error);
      PopupError("Kunde inte spara");
    }
    this.setState({ loading: false });
  }

  async Reset() {
    this.setState({ loading: true });
    try {
      let weeklyToBeSaved = {
        id: this.state.weekly.id,
        goal: "",
        important: "",
        help: "",
        strategyWorkOut: 0,
        good: "",
        bad: "",
        solution: "",
        reflection: "",
      };
      await SaveOrUpdate(weeklyToBeSaved, updateWeekly, createWeekly);
      await this.fetchWeekly();
    } catch (error) {
      console.error("Reset", error);
      PopupError("Kunde inte starta ny vecka");
    }
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return <p>Loading</p>;
    }

    if (this.state.step === steps.planing) {
      return <WeeklyPlaning weekly={this.state.weekly} Save={this.Save} />;
    } else if (
      this.state.step === steps.reflection &&
      this.state.weekly != null
    ) {
      return <WeeklyReflection weekly={this.state.weekly} Save={this.Save} />;
    } else if (this.state.step === steps.show && this.state.weekly != null) {
      return (
        <>
          <h1>Utvecklande Ledarskap</h1>
          <h2>Sammanfattning av veckan</h2>
          <WeeklyReflectionShow
            weekly={this.state.weekly}
            Reset={this.Reset}
            ShowReflection={() => this.setState({ step: steps.reflection })}
            readOnly={false}
          />
        </>
      );
    } else {
      return <p>Tomt, Error</p>;
    }
  }
}
export default Weekly;
