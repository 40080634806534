import React from "react";
import image_placeholder from "../Assets/Images/image_placeholder.jpg"
import { getPathFromStorage } from "../Util/StorageManager";

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image_placeholder,
    };
  }
  async componentDidMount() {
    if (this.props.image != null && this.props.image!=="") {
      const url = await getPathFromStorage(this.props.image);
      if (url != null) {
        this.setState({ image: url });
      }
    }
  }

  render() {
    return <img src={this.state.image} style={{ maxWidth: "100%", maxHeight: "100%" }} alt="NoImage" />;
  }
}
export default Image;
