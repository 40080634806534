import React from "react";
import { Redirect, Switch } from "react-router-dom";
import Loading from "../../Components/Loading";
import AppliedRoute from "../../Routes/AppliedRoute";
import { UnauthenticatedUrls } from "../../Routes/Urls";
import Login from "./Sections/Login";
import Recover from "./Sections/Recover";

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }
  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    const childProps = {
      isAuthenticated: this.props.app.isAuthenticated,
      userSignIn: this.props.app.userSignIn,
      userSignOut: this.props.app.userSignOut,
    };
    if (this.props.location.pathname === UnauthenticatedUrls.landing.path) {
      return <Redirect to={UnauthenticatedUrls.login.path} />;
    }

    if (this.state.isLoading) {
      return <Loading />;
    }
    
    return (
      <Switch>
        <AppliedRoute component={Login} path={UnauthenticatedUrls.login.path} props={childProps} />
        <AppliedRoute component={Recover} path={UnauthenticatedUrls.recover.path} props={childProps} />
      </Switch>
    );
  }
}

export default Landing;
