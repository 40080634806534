import React from "react";

class Empty extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   componentDidMount() {}

  render() {
    return (
      <>
        <h1>Finns inget att visa</h1>
      </>
    );
  }
}

export default Empty;
