import { AddCircle, ArrowDownward, ArrowDropUp, ArrowUpward, CheckBox, RemoveCircle } from "@material-ui/icons";
import React, { Component } from "react";
import IconButton from "../../../Components/IconButton";
import FormQuestion from "./FormQuestion";

const initialState = { selected: false, cathegories: [] };

class FormCathegories extends Component {
  render() {
    return (
      <div className="selectedTableRow">
        <div className="selectFormCathegory tableCathegory" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <h4 className="leftAlignRow">{this.props.cathegory.cathegory}</h4>

          <div style={{ width: "40px", paddingLeft: "20px" , display:"flex", flexDirection:"row"}}>
            <IconButton onClick={() => (!this.props.selected ? this.props.listSelected(this.props.cathegory) : this.props.listRemove(this.props.cathegory))}>
              {!this.props.selected && <AddCircle />}
              {this.props.selected && <RemoveCircle />}
            </IconButton>
            {this.props.selected && (
              <>
                <IconButton
                  onClick={()=>this.props.move(this.props.cathegory, -1)}
                >
                  <ArrowUpward />
                </IconButton>
                <IconButton
                  onClick={()=>this.props.move(this.props.cathegory, 1)}
                >
                  <ArrowDownward />
                </IconButton>
              </>
            )}
          </div>
        </div>
        {this.props.cathegory.questions.items
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
          .map((question) => (question.isArchived ? <React.Fragment key={question.id} /> : <FormQuestion key={question.id} question={question} />))}
      </div>
    );
  }
}
export default FormCathegories;
