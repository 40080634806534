//must be same as in cognito!!!
export const Roles = {
  admin: { value: "Admins", displaValue: "Global administratör" },
  customerAdmin: { value: "CustomerAdmins", displaValue: "Kund admininstratör" },
  customer: { value: "Users", displaValue: "Användare" },
};

export const CognitoUserStatuses = {
  unconfirmed: "UNCONFIRMED",
  confirmed: "CONFIRMED",
  forceChangePassword: "FORCE_CHANGE_PASSWORD",
};
export const MWGreen='#52685d';
export const MWGreenDisabled='#899690';
export const starColor='#0C0DA8'