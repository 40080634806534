import React from "react";

class ErrorPage extends React.Component {
  onClick = async (event) => {
    event.preventDefault();
    this.props.history.goBack();
  };

  render() {
    return (
      <div>
        <h1> Fel! </h1>
        <h2 onClick={this.onClick}>
          <p>Sidan finns inte. Gå tillbaka.</p>
        </h2>
      </div>
    );
  }
}

export default ErrorPage;
