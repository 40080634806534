import React from 'react';
import { Component } from 'react';

class DailyGoal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
        };
    }

    render() {
        return (
            <div>
                <p style={{paddingTop: "5px", paddingBottom: "5px", paddingLeft: "12px", paddingRight: "12px"}}> {this.props.text}</p>
            </div>
        );
    }
}
export default DailyGoal;