import { Grid, TextField } from "@material-ui/core";
import React, { Component } from "react";
import RoundButton from "../../../../Components/RoundButton";
import StarRating from "../../../../Components/StarRating";
import { PopupConfirm } from "../../../../Util/Util";

class WeeklyReflection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekly: this.props.weekly,
    };
    this.UpdateWeekly = this.UpdateWeekly.bind(this);
    this.Validate = this.Validate.bind(this);
  }

  UpdateWeekly(value, key) {
    let weekly = this.state.weekly;
    weekly[key] = value;
    this.setState({ weekly });
  }

  Validate() {
    if (this.state.weekly.goal === "") {
      return false;
    } else if (this.state.weekly.important === "") {
      return false;
    } else if (this.state.weekly.help === "") {
      return false;
    } else if (this.state.weekly.strategyWorkOut === "") {
      return false;
    } else if (this.state.weekly.good === "") {
      return false;
    } else if (this.state.weekly.bad === "") {
      return false;
    } else if (this.state.weekly.solution === "") {
      return false;
    } else if (this.state.weekly.reflection === "") {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <>
        <h1>Utvecklande Ledarskap</h1>
        <h2>Veckoreflektion</h2>

        <Grid container>
          <Grid item xs={12} md={4}>
            <div style={{marginBottom:"20px"}}>
              <p>Hur nådde jag veckans mål?</p>
              <StarRating
                id= {this.state.weekly.id}
                stars={this.state.weekly.strategyWorkOut}
                size="large"
                onChange={(value) =>
                  this.UpdateWeekly(value, "strategyWorkOut")
                }
              />
            </div>

            <div className="utvecklaTextInput">
              <TextField
                className="textField"
                value={this.state.weekly.good}
                onChange={(event) =>
                  this.UpdateWeekly(event.target.value, "good")
                }
                label="Vad gjorde jag bra?*"
                variant="filled"
                fullWidth
              />
            </div>
            <div className="utvecklaTextInput">
              <TextField
                className="textField"
                value={this.state.weekly.bad}
                onChange={(event) =>
                  this.UpdateWeekly(event.target.value, "bad")
                }
                label="Vad gjorde jag mindre bra?*"
                variant="filled"
                fullWidth
              />
            </div>
            <div className="utvecklaTextInput">
              <TextField
                className="textField"
                value={this.state.weekly.solution}
                onChange={(event) =>
                  this.UpdateWeekly(event.target.value, "solution")
                }
                label="Hur löser jag det?*"
                variant="filled"
                fullWidth
              />
            </div>
            <div className="utvecklaTextInput">
              <TextField
                className="textField"
                value={this.state.weekly.reflection}
                onChange={(event) =>
                  this.UpdateWeekly(event.target.value, "reflection")
                }
                label="Vad har jag lärt mig?*"
                variant="filled"
                fullWidth
              />
            </div>
          </Grid>
        </Grid>
        <RoundButton
          variant="contained"
          color="default"
          disabled={!this.Validate()}
          onClick={() =>
            PopupConfirm("Är du nöjd med din reflektion av veckan?", () =>
              this.props.Save(this.state.weekly)
            )
          }
        >
          <p>Spara</p>
        </RoundButton>
      </>
    );
  }
}
export default WeeklyReflection;
