/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      cathegoryID
      question
      order
      isArchived
      createdAt
      updatedAt
      cathegory {
        id
        cathegory
        isArchived
        createdAt
        updatedAt
        questions {
          items {
            id
            cathegoryID
            question
            order
            isArchived
            createdAt
            updatedAt
            cathegory {
              id
              cathegory
              isArchived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      cathegoryID
      question
      order
      isArchived
      createdAt
      updatedAt
      cathegory {
        id
        cathegory
        isArchived
        createdAt
        updatedAt
        questions {
          items {
            id
            cathegoryID
            question
            order
            isArchived
            createdAt
            updatedAt
            cathegory {
              id
              cathegory
              isArchived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      cathegoryID
      question
      order
      isArchived
      createdAt
      updatedAt
      cathegory {
        id
        cathegory
        isArchived
        createdAt
        updatedAt
        questions {
          items {
            id
            cathegoryID
            question
            order
            isArchived
            createdAt
            updatedAt
            cathegory {
              id
              cathegory
              isArchived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCathegory = /* GraphQL */ `
  mutation CreateCathegory(
    $input: CreateCathegoryInput!
    $condition: ModelCathegoryConditionInput
  ) {
    createCathegory(input: $input, condition: $condition) {
      id
      cathegory
      isArchived
      createdAt
      updatedAt
      questions {
        items {
          id
          cathegoryID
          question
          order
          isArchived
          createdAt
          updatedAt
          cathegory {
            id
            cathegory
            isArchived
            createdAt
            updatedAt
            questions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCathegory = /* GraphQL */ `
  mutation UpdateCathegory(
    $input: UpdateCathegoryInput!
    $condition: ModelCathegoryConditionInput
  ) {
    updateCathegory(input: $input, condition: $condition) {
      id
      cathegory
      isArchived
      createdAt
      updatedAt
      questions {
        items {
          id
          cathegoryID
          question
          order
          isArchived
          createdAt
          updatedAt
          cathegory {
            id
            cathegory
            isArchived
            createdAt
            updatedAt
            questions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCathegory = /* GraphQL */ `
  mutation DeleteCathegory(
    $input: DeleteCathegoryInput!
    $condition: ModelCathegoryConditionInput
  ) {
    deleteCathegory(input: $input, condition: $condition) {
      id
      cathegory
      isArchived
      createdAt
      updatedAt
      questions {
        items {
          id
          cathegoryID
          question
          order
          isArchived
          createdAt
          updatedAt
          cathegory {
            id
            cathegory
            isArchived
            createdAt
            updatedAt
            questions {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      receiver
      step
      giveAway
      createdAt
      updatedAt
      cathegories {
        items {
          id
          cathegoryID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          id
          formID
          questionID
          today
          want
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      receiver
      step
      giveAway
      createdAt
      updatedAt
      cathegories {
        items {
          id
          cathegoryID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          id
          formID
          questionID
          today
          want
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFormCathegory = /* GraphQL */ `
  mutation CreateFormCathegory(
    $input: CreateFormCathegoryInput!
    $condition: ModelFormCathegoryConditionInput
  ) {
    createFormCathegory(input: $input, condition: $condition) {
      id
      cathegoryID
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateFormCathegory = /* GraphQL */ `
  mutation UpdateFormCathegory(
    $input: UpdateFormCathegoryInput!
    $condition: ModelFormCathegoryConditionInput
  ) {
    updateFormCathegory(input: $input, condition: $condition) {
      id
      cathegoryID
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteFormCathegory = /* GraphQL */ `
  mutation DeleteFormCathegory(
    $input: DeleteFormCathegoryInput!
    $condition: ModelFormCathegoryConditionInput
  ) {
    deleteFormCathegory(input: $input, condition: $condition) {
      id
      cathegoryID
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      formID
      questionID
      today
      want
      priority
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      formID
      questionID
      today
      want
      priority
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      imagePath
      Users {
        items {
          id
          customerID
          createdAt
          updatedAt
        }
        nextToken
      }
      subscriptionStartDate
      subscriptinoEndDate
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      name
      imagePath
      Users {
        items {
          id
          customerID
          createdAt
          updatedAt
        }
        nextToken
      }
      subscriptionStartDate
      subscriptinoEndDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      name
      imagePath
      Users {
        items {
          id
          customerID
          createdAt
          updatedAt
        }
        nextToken
      }
      subscriptionStartDate
      subscriptinoEndDate
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      customerID
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      customerID
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      customerID
      createdAt
      updatedAt
    }
  }
`;
export const createDailyQuestion = /* GraphQL */ `
  mutation CreateDailyQuestion(
    $input: CreateDailyQuestionInput!
    $condition: ModelDailyQuestionConditionInput
  ) {
    createDailyQuestion(input: $input, condition: $condition) {
      id
      isReflection
      Question
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyQuestion = /* GraphQL */ `
  mutation UpdateDailyQuestion(
    $input: UpdateDailyQuestionInput!
    $condition: ModelDailyQuestionConditionInput
  ) {
    updateDailyQuestion(input: $input, condition: $condition) {
      id
      isReflection
      Question
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyQuestion = /* GraphQL */ `
  mutation DeleteDailyQuestion(
    $input: DeleteDailyQuestionInput!
    $condition: ModelDailyQuestionConditionInput
  ) {
    deleteDailyQuestion(input: $input, condition: $condition) {
      id
      isReflection
      Question
      createdAt
      updatedAt
    }
  }
`;
export const createDailyGoal = /* GraphQL */ `
  mutation CreateDailyGoal(
    $input: CreateDailyGoalInput!
    $condition: ModelDailyGoalConditionInput
  ) {
    createDailyGoal(input: $input, condition: $condition) {
      id
      userID
      priority
      toDo
      reflection
      goodReflection
      badReflection
      lessonLearned
      done
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyGoal = /* GraphQL */ `
  mutation UpdateDailyGoal(
    $input: UpdateDailyGoalInput!
    $condition: ModelDailyGoalConditionInput
  ) {
    updateDailyGoal(input: $input, condition: $condition) {
      id
      userID
      priority
      toDo
      reflection
      goodReflection
      badReflection
      lessonLearned
      done
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyGoal = /* GraphQL */ `
  mutation DeleteDailyGoal(
    $input: DeleteDailyGoalInput!
    $condition: ModelDailyGoalConditionInput
  ) {
    deleteDailyGoal(input: $input, condition: $condition) {
      id
      userID
      priority
      toDo
      reflection
      goodReflection
      badReflection
      lessonLearned
      done
      createdAt
      updatedAt
    }
  }
`;
export const createDailyAnswer = /* GraphQL */ `
  mutation CreateDailyAnswer(
    $input: CreateDailyAnswerInput!
    $condition: ModelDailyAnswerConditionInput
  ) {
    createDailyAnswer(input: $input, condition: $condition) {
      id
      questionDailyID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const updateDailyAnswer = /* GraphQL */ `
  mutation UpdateDailyAnswer(
    $input: UpdateDailyAnswerInput!
    $condition: ModelDailyAnswerConditionInput
  ) {
    updateDailyAnswer(input: $input, condition: $condition) {
      id
      questionDailyID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const deleteDailyAnswer = /* GraphQL */ `
  mutation DeleteDailyAnswer(
    $input: DeleteDailyAnswerInput!
    $condition: ModelDailyAnswerConditionInput
  ) {
    deleteDailyAnswer(input: $input, condition: $condition) {
      id
      questionDailyID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const createWeekly = /* GraphQL */ `
  mutation CreateWeekly(
    $input: CreateWeeklyInput!
    $condition: ModelWeeklyConditionInput
  ) {
    createWeekly(input: $input, condition: $condition) {
      id
      userID
      goal
      important
      help
      strategyWorkOut
      good
      bad
      solution
      reflection
      createdAt
      updatedAt
    }
  }
`;
export const updateWeekly = /* GraphQL */ `
  mutation UpdateWeekly(
    $input: UpdateWeeklyInput!
    $condition: ModelWeeklyConditionInput
  ) {
    updateWeekly(input: $input, condition: $condition) {
      id
      userID
      goal
      important
      help
      strategyWorkOut
      good
      bad
      solution
      reflection
      createdAt
      updatedAt
    }
  }
`;
export const deleteWeekly = /* GraphQL */ `
  mutation DeleteWeekly(
    $input: DeleteWeeklyInput!
    $condition: ModelWeeklyConditionInput
  ) {
    deleteWeekly(input: $input, condition: $condition) {
      id
      userID
      goal
      important
      help
      strategyWorkOut
      good
      bad
      solution
      reflection
      createdAt
      updatedAt
    }
  }
`;
export const createAgreement = /* GraphQL */ `
  mutation CreateAgreement(
    $input: CreateAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    createAgreement(input: $input, condition: $condition) {
      id
      text
      giveAwayText
      createdAt
      updatedAt
    }
  }
`;
export const updateAgreement = /* GraphQL */ `
  mutation UpdateAgreement(
    $input: UpdateAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    updateAgreement(input: $input, condition: $condition) {
      id
      text
      giveAwayText
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgreement = /* GraphQL */ `
  mutation DeleteAgreement(
    $input: DeleteAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    deleteAgreement(input: $input, condition: $condition) {
      id
      text
      giveAwayText
      createdAt
      updatedAt
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      receiver
      step
      giveAway
      createdAt
      updatedAt
      cathegories {
        items {
          id
          cathegoryID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          id
          formID
          questionID
          today
          want
          priority
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      formID
      questionID
      today
      want
      priority
      createdAt
      updatedAt
    }
  }
`;
