import React from "react";
import { Paper, TextField } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import {
  createCustomer,
  deleteCustomer,
  updateCustomer,
} from "../../../../graphql/mutations";
import Image from "../../../../Components/Image";
import { uploadImage } from "../../../../Util/StorageManager";
import { PopupConfirm, PopupError } from "../../../../Util/Util";
import RoundButton from "../../../../Components/RoundButton";
import { listUsers } from "../../../../graphql/queries";
import { checkAutherized } from "../../../../Routes/Urls";
import { Roles } from "../../../../Util/Const";

const acceptedRoles = [Roles.admin.value]; //Global admin

class SectionEditCustomer extends React.Component {
  constructor(props) {
    super(props);

    const initalForm = {
      name: "",
      imagePath: "",
      subscriptionStartDate: "",
      subscriptinoEndDate: "",
    };
    const customer = props.location.state?.customer;

    this.state = {
      autherized: checkAutherized(props.role, acceptedRoles, props.history),
      edit: customer != null,
      customer: customer
        ? {
            id: customer.id,
            name: customer.name,
            imagePath: customer.imagePath,
            subscriptinoEndDate: customer.subscriptinoEndDate,
            subscriptionStartDate: customer.subscriptionStartDate,
          }
        : initalForm,
      image: null,
      imageFile: null,
    };

    this.updateForm = this.updateForm.bind(this);
    this.saveCustomer = this.saveCustomer.bind(this);
    this.addImageFile = this.addImageFile.bind(this);

    this.cancel = this.cancel.bind(this);
    this.delete = this.delete.bind(this);
  }

  updateForm(key, value) {
    let customer = this.state.customer;
    customer[key] = value;
    this.setState({ customer });
  }

  async saveCustomer() {
    try {
      let newCustomer = this.state.customer;
      let imageFilePath = "";
      if (this.state.imageFile) {
        imageFilePath = await uploadImage(this.state.imageFile);
        newCustomer.imagePath = imageFilePath;
      }

      if (newCustomer.id != null) {
        await API.graphql({
          query: updateCustomer,
          variables: { input: newCustomer },
        });
      } else {
        await API.graphql({
          query: createCustomer,
          variables: { input: newCustomer },
        });
      }
      this.props.history.goBack();
    } catch (err) {
      console.error("error createing new customer", err);
    }
  }

  addImageFile(event) {
    if (event?.target?.files[0]) {
      this.setState({
        image: URL.createObjectURL(event.target.files[0]),
        imageFile: event.target.files[0],
      });
    }
  }

  cancel() {
    this.props.history.goBack();
  }

  async delete() {
    let users = (await API.graphql(graphqlOperation(listUsers)))?.data
      ?.listUsers?.items;
    let existingUsers = users
      .filter((user) => user.customerID === this.state.customer.id)
      .map((user) => user.id);
    if (existingUsers.length > 0) {
      PopupError(
        "Det finns användare tillhörande den här kunden. Ta bort all användarna innan du kan ta bort kunden"
      );
    } else {
      try {
        await API.graphql({ query: deleteCustomer, variables: { input: { id: this.state.customer.id } } });
        this.props.history.goBack();
      } catch (err) {
        console.error("error: ", err);
        PopupError("Misslyckades med att ta bort kund");
      }
    }
  }

  render() {
    if (!this.state.autherized) {
      return null;
    }
    return (
      <>
        <Paper style={{ padding: "30px" }}>
          <h1>Skapa ny kund</h1>
          <TextField
            className="textField"
            label="Namn *"
            value={this.state.customer.name}
            onChange={(event) => this.updateForm("name", event.target.value)}
          />

          <div>
            <div
              style={{
                margin: "20px",
                width: "300px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {this.state.image && (
                <img
                  src={this.state.image}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  alt="NoImage"
                />
              )}
              {!this.state.image && this.state.customer.imagePath && (
                <Image image={this.state.customer.imagePath} />
              )}
              {!this.state.image && !this.state.customer.imagePath && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p>Bild saknas</p>
                </div>
              )}
            </div>

            <input type="file" onChange={this.addImageFile} />
          </div>

          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "40px" }}
          >
            <RoundButton style={{ marginRight: "20px" }} onClick={this.cancel}>
              <p>Avbryt</p>
            </RoundButton>
            {this.state.edit && (
              <RoundButton
                style={{ marginRight: "20px" }}
                onClick={() =>
                  PopupConfirm("Vill du ta bort kunden?", () => this.delete())
                }
              >
                <p>Ta bort</p>
              </RoundButton>
            )}
            <RoundButton
              style={{ marginRight: "20px" }}
              onClick={() => this.saveCustomer()}
            >
              <p>Spara</p>
            </RoundButton>
          </div>
        </Paper>
      </>
    );
  }
}

export default SectionEditCustomer;
