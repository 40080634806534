import { Card, Grid } from "@material-ui/core";
import React, { Component } from "react";
import RoundButton from "../../../../Components/RoundButton";
import { PopupConfirm } from "../../../../Util/Util";

class DailyReflectionView extends Component {
  render() {
    return (
      <>
        <h1>Utvecklande Ledarskap</h1>
        <h2>Daglig reflektion</h2>
        <Grid container spacing={3}>
          {this.props.dailyGoals.map((dailyGoal) => (
            <Grid container item xs={12} md={4} key={dailyGoal.id}>
              <Card style={{ width: "100%", padding: "20px" }}>
                <Grid item xs={12}>
                  <h2>{dailyGoal.toDo}</h2>
                </Grid>
                <Grid item xs={12} className="gridInListStyle">
                  <p>
                    <b>Vad gick bra?</b> <br />
                    {dailyGoal.goodReflection || "-"}
                  </p>
                </Grid>
                <Grid item xs={12} className="gridInListStyle">
                  <p>
                    <b>Vad gick mindre bra? </b>
                    <br />
                    {dailyGoal.badReflection || "-"}
                  </p>
                </Grid>
                <Grid item xs={12} className="gridInListStyle">
                  <p>
                    <b>Vad har jag lärt mig? </b>
                    <br />
                    {dailyGoal.lessonLearned || "-"}
                  </p>
                </Grid>
                <Grid style={{display: "flex", alignItems:"center"}}  item xs={12} className="gridInListStyle">
                  <label className="customlabel">Mål klart</label>
                  <input type="checkbox" disabled={true} value={dailyGoal.done} checked={dailyGoal.done} />
                </Grid>
              </Card>
            </Grid>
          ))}

          <Grid container item xs={12}>
            <Card style={{ width: "100%", padding: "20px" }}>
              {this.props.dailyAnswers.map(
                (x) =>
                  !x.question.isReflection && (
                    <Grid item xs={12} className="gridInListStyle" key={x.answer.id}>
                      <p>
                        <b>{x.question.Question}</b> <br />
                        {x.answer.answer}
                      </p>
                    </Grid>
                  )
              )}
            </Card>
          </Grid>

          <Grid container item xs={12}>
            <Card style={{ width: "100%", padding: "20px" }}>
              {this.props.dailyAnswers.map(
                (x) =>
                  x.question.isReflection && (
                    <Grid item xs={12} className="gridInListStyle" key={x.answer.id}>
                      <p>
                        <b>{x.question.Question}</b>
                        <br /> {x.answer.answer}
                      </p>
                    </Grid>
                  )
              )}
            </Card>
          </Grid>

          <Grid item>
            <RoundButton onClick={() => PopupConfirm("Starta ny dag?", this.props.Reset)}>
              <p> Starta ny dag</p>
            </RoundButton>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default DailyReflectionView;
